import { ActionTypesOutputTypeInputFieldListValue, OutputTypeInputFieldListValueState, ACTIONS_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUE } from "./types";

const initialState: OutputTypeInputFieldListValueState = {
    byId: {},
}


export function outputTypeInputFieldListValuesReducer(
    state = initialState,
    action: ActionTypesOutputTypeInputFieldListValue
): OutputTypeInputFieldListValueState {
    switch (action.type) {
        case ACTIONS_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUE.INVALIDATE: {
            return {
                ...initialState,
            }
        }
        case ACTIONS_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUE.DELETE:

            let currentById = Object.assign({}, state.byId);
            delete currentById[Object.keys(action.byId)[0]];

            return {
                ...state,
                byId: currentById,
            }
        case ACTIONS_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUE.RECEIVE: {
            return {
                ...state,
                byId: { ...state.byId, ...action.byId },
            }
        }
        default:
            return state;
    }
}