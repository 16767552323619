import { RootState } from "..";
import { ICause } from "../../utilities/types/Cause";


export function selectorGetCausesByModelId(state: RootState, modelId: string): ICause[] {

    if (!state.causes || !state.causes.byId) return [];

    return sortCausesByOrderNumberAscending(Object.values(state.causes.byId).filter(x => x.modelId === modelId));
}


export function selectorGetCauseById(state: RootState, causesId: string): ICause | undefined {

    if (!state.causes || !state.causes.byId) return undefined;

    return state.causes.byId[causesId];
}


// function sortDecisionsByOrderNumberDescending(decisions: ICause[]) {
//     return decisions.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortCausesByOrderNumberAscending(causes: ICause[]) {
    return causes.sort((a, b) => a.orderNumber - b.orderNumber);
}