import cloneDeep from "lodash/cloneDeep";
import { useCallback, useState } from "react";
import { IOutputTypeInput } from "../../redux/outputJob/actions";
import { IOutputType, TOutputTypeInputFieldValue } from "../../utilities/types/OutputType";
import { IOutputTypeInputField } from "../../utilities/types/OutputTypeInputField";
import { getOutputTypeDefaultFieldValue } from "../assetInputField/defaultFieldHelper";
import { OutputInputValuesObjectMap } from "../solverJobs/WizardState";

/** Returns whether any item is loading in a map */
export function useItemIsLoading(): [boolean, (id: string, val: boolean) => void] {
  const [itemIsLoadingMap, setItemIsLoadingMap] = useState<{ [id: string]: boolean }>({});
  const setItemIsLoading = useCallback(
    (id: string, isLoading: boolean) => setItemIsLoadingMap((s) => ({ ...s, [id]: isLoading })),
    []
  );
  const itemIsLoading = Object.values(itemIsLoadingMap).some((isLoading) => isLoading);
  return [itemIsLoading, setItemIsLoading];
}

export function setupFieldValueMap(
  valuesMap: OutputInputValuesObjectMap,
  fields: IOutputTypeInputField[]
) {
  let newValuesMap: OutputInputValuesObjectMap = {};
  for (let inputField of fields) {
    let fieldValue;
    if (
      valuesMap[inputField.outputTypeId] &&
      valuesMap[inputField.outputTypeId][inputField.outputTypeInputFieldId]
    ) {
      fieldValue = valuesMap[inputField.outputTypeId][inputField.outputTypeInputFieldId];
    } else {
      fieldValue = getOutputTypeDefaultFieldValue(inputField);
    }

    let valuesObject = newValuesMap[inputField.outputTypeId] || {};
    valuesObject[inputField.outputTypeInputFieldId] = fieldValue;
    newValuesMap[inputField.outputTypeId] = valuesObject;
  }
  return { ...valuesMap, ...newValuesMap };
}

export function applyFieldChangeToValueMap(
  valuesMap: OutputInputValuesObjectMap,
  outputFieldTypeId: string,
  fieldId: string,
  value: TOutputTypeInputFieldValue
) {
  const _valuesMap = cloneDeep(valuesMap);
  const outputInputValuesObject = _valuesMap[outputFieldTypeId] || {};
  _valuesMap[outputFieldTypeId] = { ...outputInputValuesObject, [fieldId]: value };
  return _valuesMap;
}

export function getReportInputsFromValueMap(
  valuesMap: OutputInputValuesObjectMap,
  selectedOutputTypes: IOutputType[]
): { [id: string]: IOutputTypeInput } {

  return selectedOutputTypes
    .map<IOutputTypeInput>(({ outputTypeId }) => {
      let displayName = "",
        inputFieldValueMap = {};
      let outputTypeInput = valuesMap[outputTypeId];
      if (outputTypeInput) {
        let { displayName: displayNameField, ...fields } = outputTypeInput;
        inputFieldValueMap = { ...fields };
        displayName = displayNameField?.value?.toString() ?? "";
      }
      return { outputTypeId, inputFieldValueMap, displayName };
    })
    .reduce((out, type) => ({ ...out, [type.outputTypeId]: type }), {});
}
