import React from "react";
import { TextField, Grid, Divider, Button, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import { IOutputType, OutputTypeStatus } from "../../../utilities/types/OutputType";
import { fetchCreateOutputType } from "../../../redux/outputType/actions";

interface FormValues {
  name: string;
  description: string;
  inputBucket: string;
  outputBucket: string;
  status: OutputTypeStatus;
}

interface FormProps {
  onCompleteCallback(outputType?: IOutputType): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IOutputType, AnyAction>;
}

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "name", "Name")}
            fullWidth
            margin="normal"
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            onChange={props.handleChange}
            multiline
            rows={3}
            fullWidth
            margin="normal"
            variant="standard"
            {...getFormikFieldProps(props, "description", "Description")}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "inputBucket", "Input Bucket")}
            fullWidth
            margin="normal"
            variant="standard"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "outputBucket", "Output Bucket")}
            fullWidth
            margin="normal"
            variant="standard"
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth={true}>
            <InputLabel id="lbl-outputType-status" variant="standard">
              Status
            </InputLabel>
            <Select
              {...getFormikFieldProps(props, "status", "status")}
              labelId="lbl-model-status"
              variant="standard"
              style={{ display: "block" }}
              id="txt-outputType-status"
              onChange={(e) => {
                props.setFieldValue("status", e.target.value);
              }}
            >
              <MenuItem value={OutputTypeStatus.AdminOnly}>Admin Only</MenuItem>
              <MenuItem value={OutputTypeStatus.Preview}>Preview</MenuItem>
              <MenuItem value={OutputTypeStatus.Enabled}>Enabled</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <Divider light={true} />
        </Grid>

        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text" onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid>
      </Grid>
    </form>
  );
};

const FormOutputTypeCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: () => ({
    name: "",
    description: "",
    inputBucket: "",
    outputBucket: "",
    status: OutputTypeStatus.AdminOnly,
  }),
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .label("Label")
      .min(3, "Please input 3 characters or more")
      .max(50, "Please input 50 characters or less")
      .required("Please provide a name"),
    description: Yup.string().label("Description").max(500, "Please input 500 characters or less"),
    inputBucket: Yup.string()
      .label("Label")
      .min(3, "Please input 3 characters or more")
      .max(50, "Please input 50 characters or less")
      .required("Please provide an input bucket"),
    outputBucket: Yup.string()
      .label("Label")
      .min(3, "Please input 3 characters or more")
      .max(50, "Please input 50 characters or less")
      .required("Please provide an output bucket"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch } = props;

    // Map dispatch via props
    var createdOutputType = await dispatch(
      fetchCreateOutputType({
        ...values,
      })
    );

    setSubmitting(false);

    if (createdOutputType) onCompleteCallback(createdOutputType);
  },
})(InnerForm);

export default FormOutputTypeCreate;
