import React from "react";
import { Grid, Tabs, Tab, Typography, Box, AppBar, Breadcrumbs, styled } from "@mui/material";
import { Container as MuiContainer } from "@mui/material";

import { RouteComponentProps } from "react-router-dom";
import { EntityTypeEnum } from "../../../utilities/types/Entity";
import { GetUserId } from "../../../utilities/ApiUtils";
import { useFetchFilesPageHook } from "../../file/Hooks";
import { useSelector } from "react-redux";
import { selectorGetUserPermissionIsAdmin } from "../../../redux/userPermission/selectors";
import AdminImportsSection from "../ModelImportsContainer";
import { RootState } from "../../../redux";
import ModelImportWidget from "../../model/ModelImportWidget";
import SolverImportWidget from "../../solver/SolverImportWidget";
import SolverImportsContainer from "../../solver/SolverImportsContainer";
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import { GetAdminPage } from "../../../routes/RouteLinkHelpers";
import { HomeIconLink } from "../../generic/HomeIconLink";
import OutputTypeImportWidget from "../../outputType/OutputTypeImportWidget";
import OutputTypeImportsContainer from "../../outputType/OutputTypeImportsContainer";

const Container = styled(MuiContainer)(({ theme }) => ({
  "& .breadcrumbs": {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  "& .root": {
    flexGrow: 1,
    width: "100%",
    "& .appBar": {
      marginBottom: theme.spacing(0),
      boxShadow: theme.shadows[2],
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
    },
    "& .tabPanel": {
      paddingTop: theme.spacing(3),
      borderRadius: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    },
  },
}));

const PageAdminImports = (routeProps: RouteComponentProps<any>) => {
  const { fetching: fetchingModelImports } = useFetchFilesPageHook({
    pageNumber: 1,
    minPageNumberToFetch: 1,
    pageSize: 50,
    entityType: EntityTypeEnum.ModelImport,
  });
  const { fetching: fetchingSolverImports } = useFetchFilesPageHook({
    pageNumber: 1,
    minPageNumberToFetch: 1,
    pageSize: 50,
    entityType: EntityTypeEnum.SolverImport,
  });
  const { fetching: fetchingOutputTypeImports } = useFetchFilesPageHook({
    pageNumber: 1,
    minPageNumberToFetch: 1,
    pageSize: 50,
    entityType: EntityTypeEnum.OutputTypeImport,
  });
  const isAdmin = useSelector((state: RootState) =>
    selectorGetUserPermissionIsAdmin(state, GetUserId())
  );
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="xl">
      <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
        <HomeIconLink />
        <Link
          to={GetAdminPage()}
          component={RouterLink}
          color="inherit"
          key={`breadcrumb-dashboard`}
        >
          <Typography noWrap>Admin Dashboard</Typography>
        </Link>
        <Link color="textPrimary" aria-current="page" underline="none">
          Imports
        </Link>
      </Breadcrumbs>

      <div className="root">
        <AppBar position="static" className="appBar">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Models" {...a11yProps(0)} />
            <Tab label="Solvers" {...a11yProps(1)} />
            <Tab label="Output Types" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <ModelImportWidget />
            </Grid>

            <Grid item xs={12} sm={6} md={8} lg={9}>
              <AdminImportsSection
                canEdit={isAdmin}
                loading={fetchingModelImports}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <SolverImportWidget />
            </Grid>

            <Grid item xs={12} sm={6} md={8} lg={9}>
              <SolverImportsContainer
                canEdit={isAdmin}
                loading={fetchingSolverImports}
              />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <OutputTypeImportWidget />
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={9}>
              <OutputTypeImportsContainer
                canEdit={isAdmin}
                loading={fetchingOutputTypeImports}
              />
            </Grid>
          </Grid>
        </TabPanel>
      </div>
    </Container>
  );
};

function a11yProps(index: number) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
      className="tabPanel"
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

export default PageAdminImports;
