import { ISolverUIResultSetChart } from "../../utilities/types/SolverUIResultSetChart";


export const ACTIONS_SOLVER_RESULT_SET_CHART = {
    INVALIDATE: 'INVALIDATE_SOLVER_RESULT_SET_CHARTS',
    RECEIVE: 'RECEIVE_SOLVER_RESULT_SET_CHARTS',
    DELETE: 'DELETE_SOLVER_RESULT_SET_CHART',
    UPDATE: 'UPDATE_SOLVER_RESULT_SET_CHARTS',
}

interface ActionSolverUIResultSetChartsInvalidate {
    type: typeof ACTIONS_SOLVER_RESULT_SET_CHART.INVALIDATE,
    byId: { [key: string]: ISolverUIResultSetChart },
}

interface ActionSolverUIResultSetChartsReceive {
    type: typeof ACTIONS_SOLVER_RESULT_SET_CHART.RECEIVE
    byId: { [key: string]: ISolverUIResultSetChart },
}

export interface SolverUIResultSetChartState {
    byId: { [key: string]: ISolverUIResultSetChart },
}

export enum SolverUIResultSetChartSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesSolverUIResultSetChart = ActionSolverUIResultSetChartsReceive | ActionSolverUIResultSetChartsInvalidate;