import { Button, ButtonGroup, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/MenuTwoTone";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { fetchApplyCauses } from "../../redux/model/actions";
import { selectorGetUserPermissionOfType } from "../../redux/userPermission/selectors";
import { GetModelTemplateDownloadUrlByModelId } from "../../routes/RouteLinkHelpers";
import { CheckStatus, GetDefaultHeaders, GetUserId } from "../../utilities/ApiUtils";
import { TAssetInputFieldValue } from "../../utilities/types/AssetInputField";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import { IFile } from "../../utilities/types/File";
import { UserPermissionTypeEnum } from "../../utilities/types/UserPermission";
import AssetInputFieldCategoryTabs from "../assetInputFieldCategory/AssetInputFieldCategoryTabs";
import ModalFileUpload from "../file/modals/ModalFileUpload";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import useJobCreateState, { WizardStep } from "./WizardState";
import { AppliedCausesOutputDefault, USE_DEV_DEFAULTS } from "./WizardStateDefaults";

interface IJobCreateCustomiseStepProps {
  onCompleteCallback(): void;
}

function JobCreateCustomiseStep({ onCompleteCallback }: IJobCreateCustomiseStepProps) {
  const modelId = useJobCreateState((s) => s.modelId);
  const assetInputValuesObject = useJobCreateState((s) => s.assetInputValuesObject);

  const [applyingCauses, setApplyingCauses] = useState<boolean>(false);
  const canAppyCauses = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(store, GetUserId(), UserPermissionTypeEnum.ActionsApplyDecisions)
  );

  const dispatch = useDispatch();

  async function onSubmitClick() {
    if (USE_DEV_DEFAULTS) {
      useJobCreateState.setState({ appliedCausesOutput: AppliedCausesOutputDefault });
      onCompleteCallback();
      return;
    }

    setApplyingCauses(true);

    var resp: any = await dispatch(
      fetchApplyCauses({
        modelId,
        fieldValueMap: assetInputValuesObject,
        includeDisabled: false,
      })
    );

    setApplyingCauses(false);

    // Move to next step if successful
    if (resp && resp.modelId) {
      useJobCreateState.setState({ appliedCausesOutput: resp });
      onCompleteCallback();
    }
  }

  // Handle any subsequent field changes and apply them to the base object
  function applyInputFieldValueChange(fieldId: string, value: TAssetInputFieldValue) {
    const assetInputValuesObject = useJobCreateState.getState().assetInputValuesObject;
    useJobCreateState.setState({ assetInputValuesObject: { ...assetInputValuesObject, [fieldId]: value } });
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ display: "flex", flexDirection: "row", position: "relative" }}>
        <div style={{ flexBasis: "100%", textAlign: "right" }}>
          <TemplateMenu />
          <Button
            color="primary"
            variant="contained"
            disabled={!canAppyCauses}
            onClick={onSubmitClick}
            style={{ marginTop: -4 }}
            id="btn-apply"
          >
            Apply
          </Button>
          <LoaderAbsoluteCentred loading={applyingCauses} />
        </div>
      </Grid>
      <Grid item xs={12} id="custom-configuration-wrapper">
        <AssetInputFieldCategoryTabs
          modelId={modelId}
          canEdit={false}
          enableInputs={true}
          onAssetInputFieldValueChangeCallback={applyInputFieldValueChange}
          defaultValueOverrides={assetInputValuesObject}
        />
      </Grid>
    </Grid>
  );
}

const TemplateMenu = () => {
  const modelId = useJobCreateState((s) => s.modelId);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const handleUploadTemplateClick = (e: any) => {
    handleUploadModalToggle();
    handleClose(e);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (event === null || event.target === null || anchorRef === null || anchorRef.current === null) return;

    // @ts-ignore: Not potentially null, should not be erroring
    if (anchorRef.current.contains(event.target)) return;

    setOpen(false);
  };

  const handleUploadModalToggle = () => setUploadModalOpen(!uploadModalOpen);

  const onUploadCompleteCallback = (file?: IFile) => {
    if (file) {
      setUploadModalOpen(false);
      useJobCreateState.setState({
        fileId: file.fileId,
        step: WizardStep.Review,
        fileName: file.filename,
      });
    }
  };

  const handleTemplateDownload = async () => {
    if (!modelId) return;

    var headers = await GetDefaultHeaders(true, false, true);

    try {
      const response = await fetch(GetModelTemplateDownloadUrlByModelId(modelId), {
        method: "GET",
        headers: headers,
      });

      await CheckStatus(response);

      const blob = await response.blob();

      // Create a temporary URL to the blob
      const url = window.URL.createObjectURL(blob);
      // Create a temporary link element
      const a = document.createElement("a");
      a.href = url;
      // Specify the filename for the download
      a.download = `  custom-asset-update-template-${modelId}.xlsx`; // Update with your desired filename and extension
      // Append the link to the body
      document.body.appendChild(a);
      // Trigger the download
      a.click();
      // Cleanup
      window.URL.revokeObjectURL(url);
      setOpen(false);
    } catch (e: unknown) {
      console.error("Unknown error:", e);
    }
  };

  return (
    <>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        aria-label="split button"
        style={{ marginRight: 8 }}
        onClick={handleToggle}
        id="btn-template-grp"
      >
        <Button>Template</Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="Template"
          aria-haspopup="menu"
          variant="outlined"
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement="top-end">
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  <MenuItem key={`btn-download`} onClick={handleTemplateDownload}>
                    Download Template
                  </MenuItem>
                  <MenuItem key={`btn-upload`} onClick={handleUploadTemplateClick}>
                    Bulk Upload
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <ModalFileUpload
        handleCancel={handleUploadModalToggle}
        open={uploadModalOpen}
        entityId={GetUserId()}
        entityType={EntityTypeEnum.BulkAssetUpload}
        onUploadCompleteCallback={onUploadCompleteCallback}
        canSpecifyPath={false}
      />
    </>
  );
};

export default JobCreateCustomiseStep;
