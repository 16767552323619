import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import InputIcon from "@mui/icons-material/InputTwoTone";
import { ICauseAssetInputFieldMapping } from "../../../utilities/types/CauseAssetInputFieldMapping";
import FormCauseAssetInputFieldMappingUpdate from "../forms/FormCauseAssetInputFieldMappingUpdate";

export interface IModalCauseAssetInputFieldMappingUpdateProps {
  open: boolean;
  causeAssetInputFieldMapping: ICauseAssetInputFieldMapping;
  onCancelCallback(): void;
  onCompleteCallback(causeAssetInputFieldMapping?: ICauseAssetInputFieldMapping): void;
  canEdit: boolean;
}

function ModalCauseAssetInputFieldMappingUpdate({
  onCancelCallback,
  open,
  canEdit,
  onCompleteCallback,
  causeAssetInputFieldMapping,
}: IModalCauseAssetInputFieldMappingUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={true}
      title={"Update cause field mapping"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<InputIcon />}
    >
      <DialogContent>
        <FormCauseAssetInputFieldMappingUpdate
          canEdit={canEdit}
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          causeAssetInputFieldMapping={causeAssetInputFieldMapping}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalCauseAssetInputFieldMappingUpdate;
