import { ICauseAssetInputFieldMapping } from "./CauseAssetInputFieldMapping"

export interface ICause {
    causeId: string,
    modelId: string,
    name: string,
    description: string,
    status: number,
    orderNumber: number,
    discussionCount: number,
    created: Date,
    updated: Date,
    createdBy: string,
    updatedBy: string,
    actionType: CauseActionTypeEnum,
    causeAssetInputFieldMappings: ICauseAssetInputFieldMapping[]
}


export enum CauseSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}
export enum CauseActionTypeEnum {
    Enable = 0,
    ChangeBeta = 1,
    ChangeEta = 2,
    ChangeGamma = 3,
    DoNothing = 4,
    NewEta = 5,
    NewGamma = 6,
    NewBeta = 7,
    InitialAge = 8,
    ChangePF = 9,
    SetIAtoPercentOfEta = 10,
    SetQuantity = 11,
    AddPercentOfEtatoIA = 12,
    AddToInitialAge = 13,
    AddToEta = 14,
    OverwriteCostBundles = 15,
    OverwritePlanIntervals = 16,
    ScaleCosts = 17,
    DisablePlan = 18,
    AddToCosts = 19,
    SetPlanInterval = 20,
    SetPlanOffset = 21,
    AddToCostsAndSplitout = 22,
};