import { IOutputJob } from "../../utilities/types/OutputJob";

export const ACTIONS_OUTPUT_JOB = {
  INVALIDATE: "INVALIDATE_OUTPUT_JOB",
  RECEIVE: "RECEIVE_OUTPUT_JOB",
  DELETE: "DELETE_OUTPUT_JOB",
  UPDATE: "UPDATE_OUTPUT_JOB",
};

interface ActionOutputJobInvalidate {
  type: typeof ACTIONS_OUTPUT_JOB.INVALIDATE;
  byId: { [key: string]: IOutputJob };
}

interface ActionOutputJobReceive {
  type: typeof ACTIONS_OUTPUT_JOB.RECEIVE;
  byId: { [key: string]: IOutputJob };
}

export interface OutputJobState {
  byId: { [key: string]: IOutputJob };
}

export enum OutputJobSearchOrderTypeEnum {
  CreatedDateDesc = 10,
  CreatedDateAsc = 20,
}

export type ActionTypesOutputJob = ActionOutputJobReceive | ActionOutputJobInvalidate;
