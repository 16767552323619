import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import LayoutDefault from "../components/layout/LayoutDefault";
import RouteSummaryOutput from "./RouteSummaryOutput";
import PageOutputListingSearch from "../components/outputType/pages/PageOutputListingSearch";

const RouteOutputs = () => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:outputId`}>
        <RouteSummaryOutput />
      </Route>
      <Route
        path={`/outputs`}
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageOutputListingSearch {...props} />
          </LayoutDefault>
        )}
      />
    </Switch>
  );
};

export default RouteOutputs;
