import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useSelector } from "react-redux";
import HeaderIcon from "@mui/icons-material/InputTwoTone";
import { IOutputTypeInputField } from "../../../utilities/types/OutputTypeInputField";
import { DialogActions, Button, Grid, IconButton, Typography } from "@mui/material";
import ModalOutputTypeInputFieldCreate from "./ModalOutputTypeInputFieldCreate";
import { selectorGetOutputTypeInputFieldsByOutputTypeId } from "../../../redux/outputTypeInputField/selectors";
import { RootState } from "../../../redux";
import { WidgetNoResultsPlaceholder } from "../../generic/widgets/WidgetNoResultsPlaceholder";
import NoResultsIcon from "@mui/icons-material/InputTwoTone";
import { useFetchOutputTypeInputFieldsPageHook } from "../Hooks";
import OutputTypeInputFieldDisplay from "../OutputTypeInputFieldDisplay";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import EditIcon from "@mui/icons-material/EditTwoTone";
import ModalOutputTypeInputFieldUpdate from "./ModalOutputTypeInputFieldUpdate";
import { useFetchOutputTypeInputFieldListValuesPageHook } from "../../outputTypeInputFieldListValue/Hooks";

export interface IModalOutputTypeInputFieldsProps {
  open: boolean;
  onCloseCallback(): void;
  outputTypeId: string;
  canEdit: boolean;
}

function ModalOutputTypeInputFields({
  onCloseCallback,
  open,
  outputTypeId,
  canEdit,
}: IModalOutputTypeInputFieldsProps) {
  const [showOutputTypeInputFieldCreateModal, setShowOutputTypeInputFieldCreateModal] = useState(false);
  const outputTypeInputFields = useSelector((store: RootState) =>
    selectorGetOutputTypeInputFieldsByOutputTypeId(store, outputTypeId)
  );
     // Group the fields by `Tablename`
     const groupedFields = outputTypeInputFields.reduce((groups, field) => {
      const group = groups[field.tableName] || [];
      group.push(field);
      groups[field.tableName] = group;
      return groups;
    }, {} as { [Tablename: string]: IOutputTypeInputField[] });

  const [outputTypeInputFieldToUpdate, setOutputTypeInputFieldToUpdate] =
    useState<IOutputTypeInputField>();

  const { fetching: fetchingFields } = useFetchOutputTypeInputFieldsPageHook({
    pageNumber: 1,
    pageSize: 100,
    minPageNumberToFetch: 1,
    outputTypeId,
  });

  const { fetching: fetchingFieldListValues } = useFetchOutputTypeInputFieldListValuesPageHook({
    pageNumber: 1,
    pageSize: 500,
    minPageNumberToFetch: 1,
    outputTypeId,
  });

  function toggleShowOutputTypeInputFieldCreateModal() {
    setShowOutputTypeInputFieldCreateModal(!showOutputTypeInputFieldCreateModal);
  }

  function closeEditModal() {
    setOutputTypeInputFieldToUpdate(undefined);
  }

  return (
    <WidgetModalBase
      handleCancel={onCloseCallback}
      open={open}
      title={"Manage Output Type Fields"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<HeaderIcon />}
    >
      <DialogContent style={{ maxHeight: "600px" }}>
      <Grid container spacing={1}>
          {!outputTypeInputFields.length ? (
            <Grid item xs={12}>
              <WidgetNoResultsPlaceholder
                icon={NoResultsIcon}
                text="No output type fields"
                fade={true}
                spin={true}
              />
            </Grid>
          ) : null}

          {
          Object.entries(groupedFields).map(([Tablename, fields]) => (
            <Grid item xs={12} >
            <div key={Tablename}>
            <Typography variant="overline" component="p" style={{ marginBottom: 8 }}>
              {Tablename}
            </Typography>
            <Grid container spacing={1}>
              {fields.map((outputTypeInputField) => (
                <React.Fragment key={outputTypeInputField.label}>
                  <Grid item xs={9} >
                  <OutputTypeInputFieldDisplay
                    outputTypeInputField={outputTypeInputField}
                    disabled={true}
                    onValueChangeCallback={() => {}}
                  />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                    onClick={() => setOutputTypeInputFieldToUpdate(outputTypeInputField)}
                    style={{ border: "1px solid #EEE", marginBottom: -40 }}
                  >
                    <EditIcon style={{ fontSize: 15, opacity: 0.7 }} />
                  </IconButton>
                  </Grid>
                  </React.Fragment>
              ))}
              </Grid>
            </div>
            </Grid>
          ))
          }

          <LoaderAbsoluteCentred loading={fetchingFields || fetchingFieldListValues} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button  variant="outlined" style={{ display: "flex", flexGrow: 1 }} onClick={onCloseCallback}>
          Close
        </Button>
        <Button
          color="primary"
          variant="outlined"
          style={{ display: "flex", flexGrow: 1 }}
          onClick={toggleShowOutputTypeInputFieldCreateModal}
        >
          Add Field
        </Button>
      </DialogActions>

      <ModalOutputTypeInputFieldCreate
        open={showOutputTypeInputFieldCreateModal}
        onCancelCallback={toggleShowOutputTypeInputFieldCreateModal}
        onCompleteCallback={toggleShowOutputTypeInputFieldCreateModal}
        outputTypeId={outputTypeId}
      />

      {outputTypeInputFieldToUpdate !== undefined ? (
        <ModalOutputTypeInputFieldUpdate
          open={true}
          onCancelCallback={closeEditModal}
          onCompleteCallback={closeEditModal}
          outputTypeInputField={outputTypeInputFieldToUpdate}
          canEdit={canEdit}
        />
      ) : null}
    </WidgetModalBase>
  );
}

export default ModalOutputTypeInputFields;
