import { RootState } from "..";
import { ICauseAssetInputFieldMapping } from "../../utilities/types/CauseAssetInputFieldMapping";


export function selectorGetCauseAssetInputFieldMappingsByModelId(state: RootState, modelId: string): ICauseAssetInputFieldMapping[] {

    if (!state.causeAssetInputFieldMappings || !state.causeAssetInputFieldMappings.byId) return [];

    return sortCauseAssetInputFieldMappingsByOrderNumberAscending(Object.values(state.causeAssetInputFieldMappings.byId).filter(x => x.modelId === modelId));
}


export function selectorGetCauseAssetInputFieldMappingsByCauseId(state: RootState, causeId: string): ICauseAssetInputFieldMapping[] {

    if (!state.causeAssetInputFieldMappings || !state.causeAssetInputFieldMappings.byId) return [];

    return sortCauseAssetInputFieldMappingsByOrderNumberAscending(Object.values(state.causeAssetInputFieldMappings.byId).filter(x => x.causeId === causeId));
}


export function selectorGetCauseAssetInputFieldMappingById(state: RootState, causeAssetInputFieldMappingId: string): ICauseAssetInputFieldMapping | undefined {

    if (!state.causeAssetInputFieldMappings || !state.causeAssetInputFieldMappings.byId) return undefined;

    return state.causeAssetInputFieldMappings.byId[causeAssetInputFieldMappingId];
}


// function sortcauseAssetInputFieldMappingsByOrderNumberDescending(causeAssetInputFieldMappings: ICauseAssetInputFieldMapping[]) {
//     return causeAssetInputFieldMappings.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortCauseAssetInputFieldMappingsByOrderNumberAscending(causeAssetInputFieldMappings: ICauseAssetInputFieldMapping[]) {
    return causeAssetInputFieldMappings.sort((a, b) => a.orderNumber - b.orderNumber);
}