import {
  Card as MuiCard,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  Typography,
  styled,
} from "@mui/material";

import ModelIcon from "@mui/icons-material/Category";
import MoreVertIcon from "@mui/icons-material/MergeType";
import ShareIcon from "@mui/icons-material/Share";
import { Alert } from "@mui/lab";
import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  GetOutputLinkByOutputId,
  GetOutputURLByOutputId,
} from "../../routes/RouteLinkHelpers";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import { FileTypeEnum } from "../../utilities/types/File";
import { IOutputType } from "../../utilities/types/OutputType";
import FileRendererSingle from "../file/renderers/FileRendererSingle";

const IMAGE_HEIGHT = 200;

const Card = styled(MuiCard)(({ theme }) => ({
  "& .updatedModelImageWrapper": {
    height: IMAGE_HEIGHT,
    overflow: "hidden",
    cursor: "pointer",
  },
  "& .cardContent": {
    height: 60,
    overflow: "hidden",
  },
  "& .header": {
    height: 75,
  },
  "& .actionContainer": {
    paddingRight: theme.spacing(2),
  },
}));

function OutputTypeSearchResultWidget({
  outputType,
}: {
  outputType: IOutputType | undefined;
  loading: boolean;
}) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(GetOutputURLByOutputId(outputType?.outputTypeId ?? ""));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeMenu = () => setMenuAnchorEl(undefined);

  return !outputType ? null : (
    <Card>
      <CardHeader
        className="header"
        avatar={null} // NOTE: Feel free to chuck this back in, just hide it on xs, maybe sm as well
        action={
          <IconButton aria-label="settings" aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
            <MoreVertIcon style={{ transform: "rotate(90deg)" }} />
          </IconButton>
        }
        title={<Typography>{outputType?.name}</Typography>}
        subheader={`Last Updated ${moment.utc(outputType?.updated).fromNow()}`}
        subheaderTypographyProps={{ noWrap: true, variant: "caption" }}
        titleTypographyProps={{ noWrap: true, variant: "subtitle1" }}
      />

      <Menu
        style={{ padding: 0 }}
        id="simple-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        disableScrollLock={true}
        onClose={closeMenu}
      >
        <MenuItem
          key="btn-view-model"
          component={Link}
          to={GetOutputLinkByOutputId(outputType?.outputTypeId)}
        >
          <ListItemIcon>
            <ModelIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="View OutputType" />
        </MenuItem>
      </Menu>

      <div className="updatedModelImageWrapper">
        <Link to={GetOutputLinkByOutputId(outputType?.outputTypeId)}>
          <FileRendererSingle
            fileId={outputType?.mainImageId || ""}
            canUpload={false}
            fileType={FileTypeEnum.AssetImage}
            entityId={String(outputType?.outputTypeId)}
            entityType={EntityTypeEnum.OutputType}
            height={IMAGE_HEIGHT}
          />
        </Link>
      </div>
      <CardContent className="cardContent">
        <Typography variant="body2" color="textSecondary" component="p">
          {outputType.description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing className="actionContainer">
        <IconButton aria-label="Share" title="Share" onClick={handleClick}>
          <ShareIcon />
        </IconButton>
        <div style={{ flex: 1 }} />
      </CardActions>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Link copied to clipboard!
        </Alert>
      </Snackbar>
    </Card>
  );
}

export default OutputTypeSearchResultWidget;
