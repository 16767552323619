import { ACTIONS_SOLVER_RESULT_SET_CHART } from './types';
import { ISolverUIResultSetChart } from '../../utilities/types/SolverUIResultSetChart';


export const receiveSolverUIResultSetCharts = (solverUIResultSetCharts: ISolverUIResultSetChart[]) => {

    var byIdObjectToDispatch: { [key: string]: ISolverUIResultSetChart } = {};

    for (var i = 0; i < solverUIResultSetCharts.length; i++) {
        byIdObjectToDispatch[solverUIResultSetCharts[i].solverUIResultSetChartId] = solverUIResultSetCharts[i];
    }

    return {
        type: ACTIONS_SOLVER_RESULT_SET_CHART.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteSolverUIResultSetChart = (solverUIResultSetChart: ISolverUIResultSetChart) => ({
    type: ACTIONS_SOLVER_RESULT_SET_CHART.DELETE,
    byId: { [solverUIResultSetChart.solverUIResultSetChartId]: solverUIResultSetChart }
});