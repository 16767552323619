import { ICauseAssetInputFieldMapping } from "../../utilities/types/CauseAssetInputFieldMapping";


export const ACTIONS_CAUSE_ASSET_INPUT_FIELD_MAPPING = {
    INVALIDATE: 'INVALIDATE_DECISION_ASSET_INPUT_FIELD_MAPPINGS',
    RECEIVE: 'RECEIVE_DECISION_ASSET_INPUT_FIELD_MAPPINGS',
    DELETE: 'DELETE_DECISION_ASSET_INPUT_FIELD_MAPPING',
    UPDATE: 'UPDATE_DECISION_ASSET_INPUT_FIELD_MAPPINGS',
}

interface ActionCauseAssetInputFieldMappingsInvalidate {
    type: typeof ACTIONS_CAUSE_ASSET_INPUT_FIELD_MAPPING.INVALIDATE,
    byId: { [key: string]: ICauseAssetInputFieldMapping },
}

interface ActionCauseAssetInputFieldMappingsReceive {
    type: typeof ACTIONS_CAUSE_ASSET_INPUT_FIELD_MAPPING.RECEIVE
    byId: { [key: string]: ICauseAssetInputFieldMapping },
}

export interface CauseAssetInputFieldMappingState {
    byId: { [key: string]: ICauseAssetInputFieldMapping },
}

export enum CauseAssetInputFieldMappingSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesCauseAssetInputFieldMapping = ActionCauseAssetInputFieldMappingsReceive | ActionCauseAssetInputFieldMappingsInvalidate;