import { IOutputTypeInputField } from "../../utilities/types/OutputTypeInputField";


export const ACTIONS_OUTPUT_TYPE_INPUT_FIELD = {
    INVALIDATE: 'INVALIDATE_OUTPUT_TYPE_INPUT_FIELDS',
    RECEIVE: 'RECEIVE_OUTPUT_TYPE_INPUT_FIELDS',
    DELETE: 'DELETE_OUTPUT_TYPE_INPUT_FIELD',
    UPDATE: 'UPDATE_OUTPUT_TYPE_INPUT_FIELDS',
}

interface ActionOutputTypeInputFieldsInvalidate {
    type: typeof ACTIONS_OUTPUT_TYPE_INPUT_FIELD.INVALIDATE,
    byId: { [key: string]: IOutputTypeInputField },
}

interface ActionOutputTypeInputFieldsReceive {
    type: typeof ACTIONS_OUTPUT_TYPE_INPUT_FIELD.RECEIVE
    byId: { [key: string]: IOutputTypeInputField },
}

export interface OutputTypeInputFieldState {
    byId: { [key: string]: IOutputTypeInputField },
}

export enum OutputTypeInputFieldSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
    VersionNumberAsc = 30,
    VersionNumberDesc = 40,
}

export type ActionTypesOutputTypeInputField = ActionOutputTypeInputFieldsReceive | ActionOutputTypeInputFieldsInvalidate;