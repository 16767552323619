import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IFetchSearchCauseAssetInputFieldMappingsProps, fetchSearchCauseAssetInputFieldMappings } from "../../redux/causeAssetInputFieldMapping/actions";
import { ICauseAssetInputFieldMapping } from "../../utilities/types/CauseAssetInputFieldMapping";


interface IUseFetchCauseAssetInputFieldMappingsPageHookProps extends IFetchSearchCauseAssetInputFieldMappingsProps {
    minPageNumberToFetch: number;
}


export const useFetchCauseAssetInputFieldMappingsPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, modelId }: IUseFetchCauseAssetInputFieldMappingsPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<ICauseAssetInputFieldMapping[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<ICauseAssetInputFieldMapping[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve models
                var causeAssetInputFieldMappings = await dispatch(fetchSearchCauseAssetInputFieldMappings({
                    pageSize,
                    pageNumber,
                    modelId,
                })) as unknown as ICauseAssetInputFieldMapping[];

                if (causeAssetInputFieldMappings && causeAssetInputFieldMappings.length) {
                    setMorePages(causeAssetInputFieldMappings.length >= pageSize)
                    setLastResultSet(causeAssetInputFieldMappings);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, modelId]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.causeAssetInputFieldMappingId === x.causeAssetInputFieldMappingId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}