import { RootState } from "..";
import { IOutputTypeMapping } from "../../utilities/types/OutputTypeMapping";


export function selectorGetOutputTypeMappingsBySolverId(state: RootState, solverId: string): IOutputTypeMapping[] {

    if (!state.solversOutputTypeMappings || !state.solversOutputTypeMappings.byId) return [];

    return Object.values(state.solversOutputTypeMappings.byId)
        .filter(x => x.solverId === solverId);
}


export function selectorGetOutputTypeMappingById(state: RootState, outputTypeMappingId: string): IOutputTypeMapping | undefined {

    if (!state.solversOutputTypeMappings || !state.solversOutputTypeMappings.byId) return undefined;

    return state.solversOutputTypeMappings.byId[outputTypeMappingId];
}