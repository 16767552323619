import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import InputIcon from "@mui/icons-material/ReceiptTwoTone";
import { ICause } from "../../../utilities/types/Cause";
import FormCauseUpdate from "../forms/FormCauseUpdate";

export interface IModalCauseUpdateProps {
  open: boolean;
  cause: ICause;
  onCancelCallback(): void;
  onCompleteCallback(cause?: ICause): void;
  canEdit: boolean;
}

function ModalCauseUpdate({ onCancelCallback, open, onCompleteCallback, cause }: IModalCauseUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update cause"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<InputIcon />}
    >
      <DialogContent>
        <FormCauseUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          cause={cause}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalCauseUpdate;
