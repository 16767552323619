import { ACTIONS_CAUSE, CauseSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { CauseActionTypeEnum, ICause } from '../../utilities/types/Cause';


export const receiveCauses = (causes: ICause[]) => {

    var byIdObjectToDispatch: { [key: string]: ICause } = {};

    for (var i = 0; i < causes.length; i++) {
        byIdObjectToDispatch[causes[i].causeId] = causes[i];
    }

    return {
        type: ACTIONS_CAUSE.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteCause = (cause: ICause) => ({
    type: ACTIONS_CAUSE.DELETE,
    byId: { [cause.causeId]: cause }
});


export interface IFetchCreateCauseProps {
    modelId: string,
    name: string,
    description: string,
    orderNumber: number,
    actionType: CauseActionTypeEnum,
}


export const fetchCreateCause = (causeToCreate: IFetchCreateCauseProps): AppThunk<Promise<ICause>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/causes`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(causeToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.causes) {
            dispatch(receiveCauses(parsedResp.data.causes));
            return parsedResp.data.causes;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating cause.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating cause.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateCauseProps {
    causeId: string,
    name: string,
    description: string,
    orderNumber: number,
}


export const fetchUpdateCause = (props: IFetchUpdateCauseProps): AppThunk<Promise<ICause>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/causes`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.causes) {
            dispatch(receiveCauses(parsedResp.data.causes));
            return parsedResp.data.causes;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating cause.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating cause.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchCauseProps {
    pageNumber: number,
    pageSize: number,
    modelId: string,
    causeId?: string,
    text?: string,
    createdBy?: string,
    orderType?: CauseSearchOrderTypeEnum,
}


export const fetchSearchCauses = (searchParams: IFetchSearchCauseProps): AppThunk<Promise<ICause[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/causes`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.causes) {
            dispatch(receiveCauses(parsedResp.data.causes));
            return parsedResp.data.causes;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching cause.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching cause.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchDeleteCauseProps {
    causeId: string,
}


export const fetchDeleteCause = (props: IFetchDeleteCauseProps): AppThunk<Promise<ICause>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/causes`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.causes && parsedResp.data.causes.length) {
            dispatch(requestDeleteCause(parsedResp.data.causes[0]));
            return parsedResp.data.causes[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting cause.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting cause.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}