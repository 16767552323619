import { RootState } from "..";
import { IOutputType } from "../../utilities/types/OutputType";
import { selectorGetOutputTypeMappingsBySolverId } from "../solverOutputTypeMapping/selectors";


export function selectorGetOutputTypeById(state: RootState, outputTypeId: string): IOutputType | undefined {

    if (!state.outputTypes || !state.outputTypes.byId) return undefined;

    return state.outputTypes.byId[outputTypeId];
}


export function selectorGetOutputTypes(state: RootState): IOutputType[] {

    if (!state.outputTypes || !state.outputTypes.byId) return [];

    return sortOutputTypesByNameDescending(Object.values(state.outputTypes.byId));
}


export function selectorGetOutputTypesBySolverId(state: RootState, solverId: string): IOutputType[] {

    if (!state.outputTypes || !state.outputTypes.byId || !state.solversOutputTypeMappings || !state.solversOutputTypeMappings.byId) return [];

    // Retrieve all mappings that are relevant for this solver
    var mappings = selectorGetOutputTypeMappingsBySolverId(state, solverId);
    if(!mappings.length) return [];

    // Retrieve output types that have been mapped
    var outputTypes = selectorGetOutputTypes(state);
    var results = outputTypes.filter(x => mappings.some(m => m.outputTypeId === x.outputTypeId));

    return results;
}
export function sortOutputTypesByNameAsc(outputTypes: IOutputType[]): IOutputType[] {
    return outputTypes.sort((a, b) => a.name === b.name ? 0 : a.name < b.name ? -1 : 1);
}

function sortOutputTypesByNameDescending(outputTypes: IOutputType[]) {
    return outputTypes.sort((a, b) => {
        return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
    });
}