import { IOutputTypeMapping } from "../../utilities/types/OutputTypeMapping";


export const ACTIONS_SOLVER_OUTPUT_TYPE_MAPPING = {
    INVALIDATE: 'INVALIDATE_SOLVER_OUTPUT_TYPE_MAPPINGS',
    RECEIVE: 'RECEIVE_SOLVER_OUTPUT_TYPE_MAPPINGS',
    DELETE: 'DELETE_SOLVER_OUTPUT_TYPE_MAPPING',
    UPDATE: 'UPDATE_SOLVER_OUTPUT_TYPE_MAPPINGS',
}

interface ActionSolverOutputTypeMappingsInvalidate {
    type: typeof ACTIONS_SOLVER_OUTPUT_TYPE_MAPPING.INVALIDATE,
    byId: { [key: string]: IOutputTypeMapping },
}

interface ActionSolverOutputTypeMappingsReceive {
    type: typeof ACTIONS_SOLVER_OUTPUT_TYPE_MAPPING.RECEIVE
    byId: { [key: string]: IOutputTypeMapping },
}

export interface SolverOutputTypeMappingState {
    byId: { [key: string]: IOutputTypeMapping },
}

export enum SolverOutputTypeMappingSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesSolverOutputTypeMapping = ActionSolverOutputTypeMappingsReceive | ActionSolverOutputTypeMappingsInvalidate;