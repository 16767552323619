import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import BuildIcon from "@mui/icons-material/InputTwoTone";
import { ICauseAssetInputFieldMapping } from "../../../utilities/types/CauseAssetInputFieldMapping";
import FormCauseAssetInputFieldMappingCreate from "../forms/FormCauseAssetInputFieldMappingCreate";

export interface IModalCauseAssetInputFieldMappingCreateProps {
  open: boolean;
  onCancelCallback(): void;
  modelId: string;
  causeId: string;
  onCompleteCallback(causeAssetInputFieldMapping?: ICauseAssetInputFieldMapping): void;
}

function ModalCauseAssetInputFieldMappingCreate({
  onCancelCallback,
  open,
  onCompleteCallback,
  modelId,
  causeId,
}: IModalCauseAssetInputFieldMappingCreateProps) {

  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Link an input field"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<BuildIcon />}
    >
      <DialogContent>
        <FormCauseAssetInputFieldMappingCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          modelId={modelId}
          causeId={causeId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalCauseAssetInputFieldMappingCreate;
