import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { ICauseAssetInputFieldMapping } from "../../utilities/types/CauseAssetInputFieldMapping";
import { selectorGetCauseAssetInputFieldMappingsByCauseId } from "../../redux/causeAssetInputFieldMapping/selectors";
import ModalCauseAssetInputFieldMappingCreate from "./modals/ModalCauseAssetInputFieldMappingCreate";
import CauseAssetInputFieldRenderer from "./CauseAssetInputFieldMappingRenderer";

type ICauseAssetInputFieldMappingsRendererProps = {
  canEdit: boolean;
  modelId: string;
  causeId: string;
  loading: boolean;
};

const CauseAssetInputFieldMappingsRenderer = (props: ICauseAssetInputFieldMappingsRendererProps) => {
  const { causeId } = props;

  const causeAssetInputFieldMappings = useSelector((store: RootState) =>
    selectorGetCauseAssetInputFieldMappingsByCauseId(store, causeId)
  );

  return (
    <>
      <CauseAssetInputFieldMappingsRendererDisplay
        {...props}
        causeAssetInputFieldMappings={causeAssetInputFieldMappings}
      />
    </>
  );
};

interface ICauseAssetInputFieldMappingsRendererDisplayProps extends ICauseAssetInputFieldMappingsRendererProps {
  causeAssetInputFieldMappings: ICauseAssetInputFieldMapping[];
}

function CauseAssetInputFieldMappingsRendererDisplay(props: ICauseAssetInputFieldMappingsRendererDisplayProps) {
  const { causeAssetInputFieldMappings, modelId, causeId, canEdit } = props;

  const [loading] = useState(false);
  const [showCreateMappingModal, setShowCreateMappingModal] = useState(false);

  function toggleCreateMappingModal() {
    setShowCreateMappingModal(!showCreateMappingModal);
  }

  return (
    <Grid container spacing={1}>
      {causeAssetInputFieldMappings.map((causeAssetInputFieldMapping) => {
        return (
          <Grid item xs={12}>
            <CauseAssetInputFieldRenderer
              causeAssetInputFieldMappingId={causeAssetInputFieldMapping.causeAssetInputFieldMappingId}
              modelId={modelId}
              canEdit={canEdit}
            />
          </Grid>
        );
      })}

      <ModalCauseAssetInputFieldMappingCreate
        open={showCreateMappingModal}
        onCancelCallback={toggleCreateMappingModal}
        onCompleteCallback={toggleCreateMappingModal}
        modelId={modelId}
        causeId={causeId}
      />
      <LoaderAbsoluteCentred loading={loading} />
    </Grid>
  );
}

export default CauseAssetInputFieldMappingsRenderer;
