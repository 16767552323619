import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IOutputType } from "../../utilities/types/OutputType";
import { IFetchSearchOutputTypesProps, fetchSearchOutputTypes } from "../../redux/outputType/actions";


interface IUseFetchOutputTypesPageHookProps extends IFetchSearchOutputTypesProps {
    minPageNumberToFetch: number;
}


export const useFetchOutputTypesPageHook = ({ pageNumber, minPageNumberToFetch, pageSize }: IUseFetchOutputTypesPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<IOutputType[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<IOutputType[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve models
                var outputTypes = await dispatch(fetchSearchOutputTypes({
                    pageSize,
                    pageNumber,
                })) as unknown as IOutputType[];

                if (outputTypes && outputTypes.length) {
                    setMorePages(outputTypes.length >= pageSize)
                    setLastResultSet(outputTypes);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [minPageNumberToFetch, dispatch, pageNumber, pageSize]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.outputTypeId === x.outputTypeId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}