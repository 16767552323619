import { ACTIONS_OUTPUT_TYPE_INPUT_FIELD, ActionTypesOutputTypeInputField, OutputTypeInputFieldState, } from "./types";

const initialState: OutputTypeInputFieldState = {
    byId: {},
}


export function outputTypeInputFieldsReducer(
    state = initialState,
    action: ActionTypesOutputTypeInputField
): OutputTypeInputFieldState {
    switch (action.type) {
        case ACTIONS_OUTPUT_TYPE_INPUT_FIELD.INVALIDATE: {
            return {
                ...initialState,
            }
        }
        case ACTIONS_OUTPUT_TYPE_INPUT_FIELD.DELETE:

            let currentById = Object.assign({}, state.byId);
            delete currentById[Object.keys(action.byId)[0]];

            return {
                ...state,
                byId: currentById,
            }
        case ACTIONS_OUTPUT_TYPE_INPUT_FIELD.RECEIVE: {
            return {
                ...state,
                byId: { ...state.byId, ...action.byId },
            }
        }
        default:
            return state;
    }
}