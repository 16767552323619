export interface IOutputJob {
  outputJobId: string;
  solverJobId: string;
  outputTypeId: string;
  status: OutputJobStatusEnum;
  progressLog: string;
  persistentErrorMessage: number;
  created: Date;
  updated: Date;
  createdBy: string;
  updatedBy: string;
  preSignedUrl: string;
  localPath: string;
  inputBucket: string;
  outputBucket: string;
  name: string;
  prerequisites: {
    assetJobIds: string[];
    outputJobIds: string[];
    solverJobIds: string[];
  };
}

export enum OutputJobStatusEnum {
  Pending = 10,
  InProgress = 20,
  Failed = 30,
  Complete = 40,
  Cancelled = 50,
  GeneratingInProgress = 70,

}

export enum OutputJobSearchOrderTypeEnum {
  CreatedDateDesc = 10,
  CreatedDateAsc = 20,
}

export type TOutputJobInputFieldValue = {
  value: number;
  outputTypeInputFieldValueId?: string;
};


