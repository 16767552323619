import { RootState } from "..";
import { IOutputTypeInputFieldListValue } from "../../utilities/types/OutputTypeInputFieldListValue";


export function selectorGetOutputTypeInputFieldListValuesByOutputTypeId(state: RootState, outputTypeId: string): IOutputTypeInputFieldListValue[] {

    if (!state.outputTypeInputFieldListValues || !state.outputTypeInputFieldListValues.byId) return [];

    return sortOutputTypeInputFieldListValuesByOrderNumberAscending(Object.values(state.outputTypeInputFieldListValues.byId).filter(x => x.outputTypeId === outputTypeId));
}


export function selectorGetOutputTypeInputFieldListValuesByOutputTypeInputFieldId(state: RootState, outputTypeInputFieldId: string): IOutputTypeInputFieldListValue[] {

    if (!state.outputTypeInputFieldListValues || !state.outputTypeInputFieldListValues.byId) return [];

    return sortOutputTypeInputFieldListValuesByOrderNumberAscending(Object.values(state.outputTypeInputFieldListValues.byId).filter(x => x.outputTypeInputFieldId === outputTypeInputFieldId));
}


export function selectorGetOutputTypeInputFieldListValueById(state: RootState, outputTypeInputFieldListValueId: string): IOutputTypeInputFieldListValue | undefined {

    if (!state.outputTypeInputFieldListValues || !state.outputTypeInputFieldListValues.byId) return undefined;

    return state.outputTypeInputFieldListValues.byId[outputTypeInputFieldListValueId];
}


// function sortSummaryOutputTypeInputFieldListValuesByOrderNumberDescending(summaryOutputTypeInputFieldListValues: IOutputTypeInputFieldListValue[]) {
//     return summaryOutputTypeInputFieldListValues.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortOutputTypeInputFieldListValuesByOrderNumberAscending(outputTypeInputFieldListValues: IOutputTypeInputFieldListValue[]) {
    return outputTypeInputFieldListValues.sort((a, b) => a.orderNumber - b.orderNumber);
}