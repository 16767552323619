import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch, useSelector } from "react-redux";
import { IOutputType } from "../../../utilities/types/OutputType";
import MapIcon from "@mui/icons-material/MapRounded";
import { RootState } from "../../../redux";
import FileRendererSingle from "../../file/renderers/FileRendererSingle";
import { FileTypeEnum } from "../../../utilities/types/File";
import { EntityTypeEnum } from "../../../utilities/types/Entity";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { selectorGetOutputTypeById } from "../../../redux/outputType/selectors";
import { fetchSearchOutputTypes } from "../../../redux/outputType/actions";
import FormOutputTypeUpdate from "../forms/FormOutputTypeUpdate";

export interface IModalOutputTypeUpdateProps {
  open: boolean;
  outputType: IOutputType;
  onCancelCallback(): void;
  onCompleteCallback(outputType?: IOutputType): void;
  canEdit: boolean;
}

function ModalOutputTypeUpdate({
  onCancelCallback,
  open,
  onCompleteCallback,
  outputType,
  canEdit,
}: IModalOutputTypeUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update an output type"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<MapIcon />}
    >
      <DialogContent>
        <RenderImage outputTypeId={outputType.outputTypeId} canEdit={canEdit} />
        <FormOutputTypeUpdate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          outputType={outputType}
          canEdit={canEdit}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

interface IRenderImageProps {
  outputTypeId: string;
  canEdit: boolean;
}

function RenderImage({ outputTypeId, canEdit }: IRenderImageProps) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const identifier = useSelector((store: RootState) => selectorGetOutputTypeById(store, outputTypeId));

  if (!identifier) return null;

  const onUploadComplete = async () => {
    setLoading(true);
    await dispatch(
      fetchSearchOutputTypes({
        outputTypeId,
        pageNumber: 1,
        pageSize: 1,
      })
    );
    setLoading(false);
  };

  return (
    <div style={{ borderRadius: 4, position: "relative" }}>
      <FileRendererSingle
        fileId={identifier.mainImageId || ""}
        canUpload={canEdit}
        fileType={FileTypeEnum.Image}
        entityId={String(identifier.outputTypeId)}
        entityType={EntityTypeEnum.OutputType}
        height={150}
        onUploadComplete={onUploadComplete}
      />
      <LoaderAbsoluteCentred loading={loading} />
    </div>
  );
}

export default ModalOutputTypeUpdate;
