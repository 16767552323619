import React, { useState } from "react";
import { Grid, Button, styled } from "@mui/material";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { IModel } from "../../utilities/types/Model";
import { green } from "@mui/material/colors";
import AddCategoryIcon from "@mui/icons-material/AddCircleOutline";
import ModalCauseCreate from "./modals/ModalCauseCreate";

const GridWrapper = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  position: "relative",
  alignItems: "center",
  "& .addCauseButton": {
    color: green[500],
    fontWeight: 500,
    textTransform: "capitalize",
    marginRight: theme.spacing(3),
    transition: "background 200ms ease-out",
    width: "100%",
  },
}));

type ICauseModelNewModelProps = {
  model?: IModel;
};

const ModelBuilderNewCause = ({ model }: ICauseModelNewModelProps) => {
  const [showCreateCauseModal, setShowCreateCauseModal] = useState(false);

  const [loading] = useState(false);

  function toggleshowCreateCauseModal() {
    setShowCreateCauseModal(!showCreateCauseModal);
  }

  function onCompleteCallback() {
    setShowCreateCauseModal(!showCreateCauseModal);
  }

  return (
    <GridWrapper container>
      <Grid item xs={12}>
        <Button
          id="btn-create-cause"
          variant="outlined"
          className="addCauseButton"
          onClick={toggleshowCreateCauseModal}
        >
          <AddCategoryIcon style={{ marginRight: "8px" }} /> Add new cause logic
        </Button>
      </Grid>
      <ModalCauseCreate
        open={showCreateCauseModal}
        onCancelCallback={toggleshowCreateCauseModal}
        onCompleteCallback={onCompleteCallback}
        modelId={model?.modelId ?? ""}
      />
      <LoaderAbsoluteCentred loading={loading} />
    </GridWrapper>
  );
};

export default ModelBuilderNewCause;
