export enum EntityTypeEnum {
  User = 10,
  Solver = 20,
  Model = 30,
  Component = 40,
  Identifier = 50,
  Function = 60,
  FunctionalFailure = 70,
  FailureMode = 80,
  ModelImport = 90,
  SingleAssetOutput = 100,
  SolverImport = 110,
  OutputTypeImport = 120,
  Video = 130,
  VideoImport = 140,
  Discussion = 150,
  Feedback = 160,
  OutputType = 170,
  SummaryOutputTypeImport = 180,
  BulkAssetUpload = 190,
  SolverJob = 200,
  SolverJobTask = 210,
  Cause = 220,
  SolverDetails = 230,
}
