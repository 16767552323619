import { RootState } from "..";
import { IOutputTypeInputField } from "../../utilities/types/OutputTypeInputField";


export function selectorGetOutputTypeInputFieldsByOutputTypeId(state: RootState, outputTypeId: string): IOutputTypeInputField[] {

    if (!state.outputTypeInputFields || !state.outputTypeInputFields.byId) return [];

    return sortOutputTypeInputFieldsByOrderNumberAscending(Object.values(state.outputTypeInputFields.byId).filter(x => x.outputTypeId === outputTypeId));
}

export function selectorGetOutputTypeInputFieldById(state: RootState, outputTypeInputFieldId: string): IOutputTypeInputField | undefined {

    if (!state.outputTypeInputFields || !state.outputTypeInputFields.byId) return undefined;

    return state.outputTypeInputFields.byId[outputTypeInputFieldId];
}


export function selectorGetOutputTypeInputFields(state: RootState) {

    if (!state.outputTypeInputFields || !state.outputTypeInputFields.byId) return [];

    return Object.values(state.outputTypeInputFields.byId);
}


export function selectorGetOutputTypeInputFieldTableNamesByOutputTypeId(state: RootState, outputTypeId: string) {

    var outputTypeInputFields = selectorGetOutputTypeInputFieldsByOutputTypeId(state, outputTypeId);
    var tableNames = outputTypeInputFields.filter(x => x.tableName && x.tableName !== '').map(x => x.tableName);
    var distinctTableNames = new Set(tableNames);

    return Array.from(distinctTableNames.values());
}


// function sortSummaryOutputTypeInputFieldsByOrderNumberDescending(outputTypeInputFields: IOutputTypeInputField[]) {
//     return outputTypeInputFields.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortOutputTypeInputFieldsByOrderNumberAscending(outputTypeInputFields: IOutputTypeInputField[]) {
    return outputTypeInputFields.sort((a, b) => a.orderNumber - b.orderNumber);
}