import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import { IOutputTypeMapping } from "../../utilities/types/OutputTypeMapping";
import { IOutputType } from "../../utilities/types/OutputType";
import { ListItemAvatar, Avatar, Typography, styled, ListItemButton } from "@mui/material";
import { FileTypeEnum } from "../../utilities/types/File";
import { useFileSourceSingleHook } from "../file/Hooks";

const GridWrapper = styled(Grid)(({ theme }) => ({
  margin: "auto",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  flexWrap: "wrap",
  width: "100%",

  "& .list": {
    flex: 1,
    maxWidth: 400,
    minWidth: 250,
  },
  "& .listItem": {
    border: "1px solid rgba(0,0,0,0.1)",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    width: "calc(100% - 16px)",
    overflow: "hidden",
    backgroundColor: "rgba(255,255,255,1)",
  },
  "& .listLeft": {
    backgroundColor: "rgba(0,0,0,0.02)",
  },
  "& .listRight": {
    backgroundColor: "rgba(0,0,0,0.02)",
  },
  "& .paper": {
    height: 230,
    overflow: "auto",
    border: "1px solid rgba(0,0,0,0.1)",
    boxShadow: "none",
    padding: 0,
  },
  "& .button": {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a: IOutputType[], b: IOutputType[]) {
  return a.filter((value) => !b.some((c) => c.outputTypeId === value.outputTypeId));
}

function getOutputTypesByMappings(
  outputTypes: IOutputType[],
  mappings: IOutputTypeMapping[]
) {
  return outputTypes.filter((x) => mappings.some((y) => y.outputTypeId === x.outputTypeId));
}

interface IOutputTypePickListProps {
  solverId: string;
  onSelectedItemsChange(mappings: IOutputType[]): void;
  outputTypes: IOutputType[];
  outputTypeMappings: IOutputTypeMapping[];
  leftLabel?: string;
  rightLabel?: string;
}

export default function SolverOutputTypePickList({
  outputTypes,
  outputTypeMappings,
  onSelectedItemsChange,
  leftLabel,
  rightLabel,
}: IOutputTypePickListProps) {
  const [right, setRight] = useState<IOutputType[]>(
    getOutputTypesByMappings(outputTypes, outputTypeMappings)
  );
  const [left, setLeft] = useState<IOutputType[]>(not(outputTypes, right));
  const [assignedMappingsCount, setAssignedMappingsCount] = useState<number>(outputTypeMappings.length);
  const [outputTypesCount, setoutputTypesCount] = useState<number>(outputTypes.length);

  function selectItem(clickedItem: IOutputType, rightItems: boolean) {
    if (rightItems) {
      let mergedItems = [...left, clickedItem];
      setLeft(mergedItems);
      setRight(not(outputTypes, mergedItems));
    } else {
      let mergedItems = [...right, clickedItem];
      setRight(mergedItems);
      setLeft(not(outputTypes, mergedItems));
    }
  }

  // Allow parent component to hook into current items
  useEffect(() => {
    onSelectedItemsChange(right);
  }, [right, onSelectedItemsChange]);

  // Ensure that mappings not initially available are still catered for
  useEffect(() => {
    if (
      outputTypeMappings.length !== assignedMappingsCount ||
      outputTypes.length !== outputTypesCount
    ) {
      setAssignedMappingsCount(outputTypeMappings.length);
      setoutputTypesCount(outputTypes.length);

      var rightoutputTypes = getOutputTypesByMappings(outputTypes, outputTypeMappings);
      setRight(rightoutputTypes);
      setLeft(not(outputTypes, rightoutputTypes));
    }
  }, [outputTypeMappings, assignedMappingsCount, outputTypes, outputTypesCount]);

  const customList = (items: IOutputType[], right: boolean) => (
    <Paper className={`paper ${right ? "listRight" : "listLeft"}`}>
      <List dense component="div" role="list" style={{ paddingTop: 0 }}>
        {items.map((value) => (
          <CustomListItem
            key={value.outputTypeId}
            outputType={value}
            onClick={() => selectItem(value, right)}
          />
        ))}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <GridWrapper container spacing={2} justifyContent="center" alignItems="center" className="listContainer">
      <Grid item className="list">
        <Typography variant="overline">{leftLabel || "Unlinked"}</Typography>
        {customList(left, false)}
      </Grid>
      <Grid item className="list">
        <Typography variant="overline">{rightLabel || "Linked"}</Typography>
        {customList(right, true)}
      </Grid>
    </GridWrapper>
  );
}

function CustomListItem({ outputType, onClick }: { outputType: IOutputType; onClick(): void }) {
  const labelId = `transfer-list-item-${outputType.outputTypeId}-label`;
  const imageUrl = useFileSourceSingleHook({
    fileId: outputType && outputType.mainImageId ? outputType.mainImageId : "",
    fileType: FileTypeEnum.Image,
  });

  return (
    <ListItemButton key={outputType.outputTypeId} role="listitem" onClick={onClick} className="listItem">
      <ListItemAvatar>
        <Avatar alt={outputType.name} src={imageUrl} />
      </ListItemAvatar>
      <ListItemText id={labelId} primary={outputType.name} />
    </ListItemButton>
  );
}
