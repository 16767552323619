import { Button, DialogActions, Typography, styled } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import MapIcon from "@mui/icons-material/MapRounded";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { selectorGetOutputTypeById } from "../../../redux/outputType/selectors";
import { selectorGetOutputTypeInputFieldsByOutputTypeId } from "../../../redux/outputTypeInputField/selectors";
import { selectorGetOutputTypeInputFieldListValuesByOutputTypeId } from "../../../redux/outputTypeInputFieldListValue/selectors";
import { DeHumanizeString } from "../../../utilities/Helpers";
import { IOutputType } from "../../../utilities/types/OutputType";
import {
  IOutputTypeInputField,
  OutputTypeInputFieldTypeEnum,
} from "../../../utilities/types/OutputTypeInputField";
import { IOutputTypeInputFieldListValue } from "../../../utilities/types/OutputTypeInputFieldListValue";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { WidgetNoResultsPlaceholder } from "../../generic/widgets/WidgetNoResultsPlaceholder";
import { useFetchOutputTypeInputFieldsPageHook } from "../../outputTypeInputField/Hooks";
import { useFetchOutputTypeInputFieldListValuesPageHook } from "../../outputTypeInputFieldListValue/Hooks";

const PreBlock = styled("pre")(({ theme }) => ({
  width: "100%",
  overflow: "scroll",
  border: "none",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "rgba(0,0,0,0.8)",
  color: "#FFF",
  fontFamily:
    "Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif",
  padding: theme.spacing(1),
  maxHeight: 150,
  marginTop: 0,
}));

export interface IModalOutputTypeDefinitionsProps {
  open: boolean;
  onCancelCallback(): void;
  outputTypeId: string;
}

function ModalOutputTypeDefinitions({
  onCancelCallback,
  open,
  outputTypeId,
}: IModalOutputTypeDefinitionsProps) {
  const outputType = useSelector((store: RootState) =>
    selectorGetOutputTypeById(store, outputTypeId)
  );
  const outputTypeFields = useSelector((store: RootState) =>
    selectorGetOutputTypeInputFieldsByOutputTypeId(store, outputTypeId)
  );
  const outputTypeFieldValues = useSelector((store: RootState) =>
    selectorGetOutputTypeInputFieldListValuesByOutputTypeId(store, outputTypeId)
  );

  const { fetching: fetchingFields } = useFetchOutputTypeInputFieldsPageHook({
    pageNumber: 1,
    pageSize: 100,
    outputTypeId: outputTypeId,
    minPageNumberToFetch: 1,
  });

  const { fetching: fetchingFieldValues } = useFetchOutputTypeInputFieldListValuesPageHook({
    pageNumber: 1,
    pageSize: 500,
    outputTypeId: outputTypeId,
    minPageNumberToFetch: 1,
  });

  const hasData = !!outputType && !!outputTypeFields.length && !!outputTypeFieldValues.length;
  const fetching = !hasData && (fetchingFields || fetchingFieldValues);

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Output Type Class Definition"}
      subtitle={"Copy and paste into the report project."}
      headerIcon={<MapIcon />}
      style={{ maxWidth: "1200px" }}
    >
      <DialogContent>
        <LoaderAbsoluteCentred loading={fetching} />
        {!outputType ? (
          <WidgetNoResultsPlaceholder text="No output type" />
        ) : (
          <>
            <Typography variant="overline">Output Type Id</Typography>
            <PreBlock>public const string Id = "{outputType.outputTypeId}";</PreBlock>

            <Typography variant="overline">Field Definitions</Typography>
            <PreBlock>{ClassFieldDefinitions({ outputType, outputTypeFields })}</PreBlock>

            <Typography variant="overline">Field Value Definitions</Typography>
            <PreBlock>
              {ClassFieldListValueDefinitions({
                outputType,
                outputTypeFields,
                outputTypeFieldValues,
              })}
            </PreBlock>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" fullWidth={true} onClick={onCancelCallback}>
          Close
        </Button>
      </DialogActions>
    </WidgetModalBase>
  );
}

function ClassFieldListValueDefinitions({
  outputType,
  outputTypeFields,
  outputTypeFieldValues,
}: {
  outputType: IOutputType;
  outputTypeFields: IOutputTypeInputField[];
  outputTypeFieldValues: IOutputTypeInputFieldListValue[];
}) {
  var fieldsDefinition = `public static class ${DeHumanizeString(outputType.name)}FieldValues\r\n`;
  fieldsDefinition += `{\r\n`;

  // Add each field to the definition
  for (var outputTypeField of outputTypeFields) {
    // We only need this for enumerations
    if (outputTypeField.type !== OutputTypeInputFieldTypeEnum.CustomEnumeration) continue;

    fieldsDefinition += `\tpublic static class ${DeHumanizeString(outputTypeField.label)}FieldValues\r\n`;
    fieldsDefinition += `\t{\r\n`;

    // Add each of the field value properties
    for (var fieldValue of outputTypeFieldValues) {
      // Skip values that don't belong to this field
      if (fieldValue.outputTypeInputFieldId !== outputTypeField.outputTypeInputFieldId) continue;

      fieldsDefinition += `\t\tpublic const string ${DeHumanizeString(fieldValue.displayText)} = "${
        fieldValue.outputTypeInputFieldListValueId
      }";\r\n`;
    }

    fieldsDefinition += `\t}\r\n`;
    fieldsDefinition += `\r\n`;
  }

  fieldsDefinition += `}\r\n`;

  return fieldsDefinition;
}

function ClassFieldDefinitions({
  outputType,
  outputTypeFields,
}: {
  outputType: IOutputType;
  outputTypeFields: IOutputTypeInputField[];
}) {
  var fieldsDefinition = `public static class ${DeHumanizeString(outputType.name)}Fields\r\n`;
  fieldsDefinition += `{\r\n`;

  // Add each field to the definition
  for (var outputTypeField of outputTypeFields) {
    fieldsDefinition += `\tpublic const string ${DeHumanizeString(outputTypeField.label)} = "${
      outputTypeField.outputTypeInputFieldId
    }";\r\n`;
  }

  fieldsDefinition += `}\r\n`;

  return fieldsDefinition;
}

export default ModalOutputTypeDefinitions;
