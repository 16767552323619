import { ACTIONS_OUTPUT_TYPE_INPUT_FIELD, OutputTypeInputFieldSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { IOutputTypeInputField, OutputTypeInputFieldTypeEnum } from '../../utilities/types/OutputTypeInputField';


export const receiveOutputTypeInputFields = (outputTypeInputFields: IOutputTypeInputField[]) => {

    var byIdObjectToDispatch: { [key: string]: IOutputTypeInputField } = {};

    for (var i = 0; i < outputTypeInputFields.length; i++) {
        byIdObjectToDispatch[outputTypeInputFields[i].outputTypeInputFieldId] = outputTypeInputFields[i];
    }

    return {
        type: ACTIONS_OUTPUT_TYPE_INPUT_FIELD.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteOutputTypeInputField = (outputTypeInputField: IOutputTypeInputField) => ({
    type: ACTIONS_OUTPUT_TYPE_INPUT_FIELD.DELETE,
    byId: { [outputTypeInputField.outputTypeInputFieldId]: outputTypeInputField }
});


export interface IFetchCreateOutputTypeInputFieldProps {
    outputTypeId: string,
    label: string,
    description?: string,
    defaultValue: number,
    minValue?: number,
    maxValue?: number,
    orderNumber: number,
    type: OutputTypeInputFieldTypeEnum,
}


export const fetchCreateOutputTypeInputField = (outputTypeInputFieldToCreate: IFetchCreateOutputTypeInputFieldProps): AppThunk<Promise<IOutputTypeInputField>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/outputTypeInputFields`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(outputTypeInputFieldToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.outputTypeInputField) {
            dispatch(receiveOutputTypeInputFields([parsedResp.data.outputTypeInputField]));
            return parsedResp.data.outputTypeInputField;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating input field.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating input field.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateOutputTypeInputFieldProps {
    outputTypeInputFieldId: string,
    label: string,
    description?: string,
    defaultValue: number,
    defaultString?: string,
    defaultDateTime?: Date,
    minValue?: number,
    maxValue?: number,
    decimalPlaces?: number,
    orderNumber: number,
    type: OutputTypeInputFieldTypeEnum,
}


export const fetchUpdateOutputTypeInputField = (props: IFetchUpdateOutputTypeInputFieldProps): AppThunk<Promise<IOutputTypeInputField>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/outputTypeInputFields`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.outputTypeInputFields) {
            dispatch(receiveOutputTypeInputFields([parsedResp.data.outputTypeInputFields]));
            return parsedResp.data.outputTypeInputFields;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating input field.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating input field.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchOutputTypeInputFieldsProps {
    pageNumber: number,
    pageSize: number,
    outputTypeId?: string,
    text?: string,
    createdBy?: string,
    orderType?: OutputTypeInputFieldSearchOrderTypeEnum,
}


export const fetchSearchOutputTypeInputFields = (searchParams: IFetchSearchOutputTypeInputFieldsProps): AppThunk<Promise<IOutputTypeInputField[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/outputTypeInputFields`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.outputTypeInputFields) {
            dispatch(receiveOutputTypeInputFields(parsedResp.data.outputTypeInputFields));
            return parsedResp.data.outputTypeInputFields;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching input field.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching input field.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteOutputTypeInputFieldProps {
    outputTypeInputFieldId: string,
}


export const fetchDeleteOutputTypeInputField = (props: IFetchDeleteOutputTypeInputFieldProps): AppThunk<Promise<IOutputTypeInputField>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/outputTypeInputFields`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.outputTypeInputField) {
            dispatch(requestDeleteOutputTypeInputField(parsedResp.data.outputTypeInputField));
            return parsedResp.data.outputTypeInputField;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting input field.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting input field.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}