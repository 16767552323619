import { AppThunk } from "..";
import {
  AddQueryStringsToUrl,
  CheckStatus,
  GetDefaultHeaders,
  GetUserId,
  ShowExceptionAsMessage,
} from "../../utilities/ApiUtils";
import { Configuration } from "../../utilities/Constants";
import { PrepareBody, ShowError } from "../../utilities/Helpers";
import { IApiResponse } from "../../utilities/types/Api";
import { TAssetInputFieldValue } from "../../utilities/types/AssetInputField";
import {
  IModel,
  Maturity,
  ModelFilterEnum,
  ModelSearchOrderTypeEnum,
  ModelStatusEnum,
} from "../../utilities/types/Model";
import { receiveComponents } from "../component/actions";
import { ACTIONS_COMPONENT } from "../component/types";
import { fetchSearchEntityPermissions } from "../entityPermission/actions";
import { receiveFailureModes } from "../failureMode/actions";
import { ACTIONS_FAILURE_MODE } from "../failureMode/types";
import { receiveFunctions } from "../function/actions";
import { ACTIONS_FUNCTION } from "../function/types";
import { receiveFunctionalFailures } from "../functionalFailure/actions";
import { ACTIONS_FUNCTIONAL_FAILURE } from "../functionalFailure/types";
import { selectorGetModelById } from "./selectors";
import { ACTIONS_MODEL } from "./types";

export const receiveModels = (models: IModel[]) => {
  var byIdObjectToDispatch: { [key: string]: IModel } = {};

  for (var i = 0; i < models.length; i++) {
    byIdObjectToDispatch[models[i].modelId] = models[i];
  }

  return {
    type: ACTIONS_MODEL.RECEIVE,
    byId: byIdObjectToDispatch,
  };
};

export const requestDeleteModel = (model: IModel) => ({
  type: ACTIONS_MODEL.DELETE,
  byId: { [model.modelId]: model },
});

export interface IFetchDeleteModelProps {
  modelId: string;
}

export const fetchDeleteModel =
  (props: IFetchDeleteModelProps): AppThunk<Promise<IModel>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/models`, props), {
        method: "DELETE",
        headers: headers,
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.model && parsedResp.data.model) {
        dispatch(requestDeleteModel(parsedResp.data.model));
        return parsedResp.data.model;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error deleting model.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error deleting model.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export interface IFetchSearchModelsProps extends IFetchSearchModelsFilterProps {
  pageNumber: number;
  pageSize: number;
  modelId?: string;
  text?: string;
  status?: ModelStatusEnum;
  versionNumber?: number;
  createdBy?: string;
  orderType?: ModelSearchOrderTypeEnum;
}

export interface IFetchSearchModelsFilterProps {
  favouriteFilterType?: ModelSearchFavouriteFilterType;
  maturityFilterType?: Maturity;
  modelFilterType?: ModelFilterEnum;
}

export enum ModelSearchFavouriteFilterType {
  IsFavourite = 10,
}

export interface FetchSearchModelsReturnType {
  models: IModel[];
  totalCount: number;
}

const defaultSearchModelsValues = {
  models: [],
  totalCount: 1,
};

export const fetchSearchModels =
  (searchParams: IFetchSearchModelsProps): AppThunk<Promise<FetchSearchModelsReturnType>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, false, true);

    try {
      var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/models`, searchParams), {
        method: "GET",
        headers: headers,
      });

      // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.models) {
        dispatch(receiveModels(parsedResp.data.models));
        return { models: parsedResp.data.models, totalCount: parsedResp.data.totalCount };
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error while searching models.");
        }
        return defaultSearchModelsValues;
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error searching models.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return defaultSearchModelsValues;
    }
  };

export interface IFetchCreateModelProps {
  title: string;
  description?: string;
  versionNumber: number;
}

export const fetchCreateModel =
  (modelToCreate: IFetchCreateModelProps): AppThunk<Promise<IModel>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(`${Configuration.BASE_API_URL}/models`, {
        method: "POST",
        headers: headers,
        body: PrepareBody(modelToCreate),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.model) {
        dispatch(receiveModels([parsedResp.data.model]));

        // Re-fetch permissions so that we can perform model build actions
        await dispatch(
          fetchSearchEntityPermissions({
            userDetailId: GetUserId(),
            pageNumber: 1,
            pageSize: 100,
          })
        );

        return parsedResp.data.model;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error while creating model.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error retrieving model.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

interface IFetchApplyCauses {
  modelId: string;
  fieldValueMap: { [key: string]: TAssetInputFieldValue };
  includeDisabled: boolean;
}

export const fetchApplyCauses =
  (params: IFetchApplyCauses): AppThunk<Promise<IModel>> =>
  async (dispatch) => {
    const headers = await GetDefaultHeaders(true, true);
    const controller = new AbortController();
    const timeout = 10000; // Set the timeout 10 seconds

    const timeoutId = setTimeout(() => {
      controller.abort();
    }, timeout);

    try {
      const apiResponse = await fetch(`${Configuration.BASE_API_URL}/models/applyDecisions`, {
        method: "POST",
        headers: headers,
        body: PrepareBody(params),
        signal: controller.signal, // Add the abort signal to the fetch request
      });

      clearTimeout(timeoutId); // Clear the timeout if the request completes successfully

      const parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.customisedAsset) {
        const model = parsedResp.data.customisedAsset;
        const components = [];
        const functions = [];
        const functionalFailures = [];
        const failureModes = [];

        // Normalise model
        for (const component of model.components) {
          for (const functionObj of component.functions) {
            for (const functionalFailure of functionObj.functionalFailures) {
              for (const failureMode of functionalFailure.failureModes) {
                failureModes.push(failureMode);
              }

              delete functionalFailure.failureModes;
              functionalFailures.push(functionalFailure);
            }

            delete functionObj.functionalFailures;
            functions.push(functionObj);
          }
          delete component.functions;
          components.push(component);
        }

        delete model.components;

        // Ensure that existing state is removed so that disabled elements still show
        dispatch({ type: ACTIONS_COMPONENT.INVALIDATE });
        dispatch({ type: ACTIONS_FUNCTION.INVALIDATE });
        dispatch({ type: ACTIONS_FUNCTIONAL_FAILURE.INVALIDATE });
        dispatch({ type: ACTIONS_FAILURE_MODE.INVALIDATE });

        // Provide output
        dispatch(receiveModels([model]));
        dispatch(receiveComponents(components));
        dispatch(receiveFunctions(functions));
        dispatch(receiveFunctionalFailures(functionalFailures));
        dispatch(receiveFailureModes(failureModes));

        return parsedResp.data.customisedAsset;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error while creating model.");
          return null;
        }
      }
    } catch (e: unknown) {
      clearTimeout(timeoutId); // Clear the timeout in case of an error

      if (e instanceof DOMException && e.name === "AbortError") {
        ShowError("Request timed out. Please try again later.");
      } else if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error retrieving model.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export interface IFetchUpdateModelProps {
  title: string;
  description?: string;
  versionNumber: number;
  modelId: string;
  status: number;
}

export const fetchUpdateModel =
  (props: IFetchUpdateModelProps): AppThunk<Promise<IModel>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(`${Configuration.BASE_API_URL}/models`, {
        method: "PUT",
        headers: headers,
        body: PrepareBody(props),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.model) {
        dispatch(receiveModels([parsedResp.data.model]));
        return parsedResp.data.model;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error while updating model.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error retrieving model.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export const fetchModelByIdIfNeeded =
  (modelId: string): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    if (!selectorGetModelById(getState(), modelId)) {
      await dispatch(fetchSearchModels({ modelId: modelId, pageNumber: 1, pageSize: 1 }));
    }

    return;
  };

export interface IFetchImportModelProps {
  fileId: string;
}

export const fetchImportModel =
  (props: IFetchImportModelProps): AppThunk<Promise<IModel>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(`${Configuration.BASE_API_URL}/models/import`, {
        method: "POST",
        headers: headers,
        body: PrepareBody(props),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (
        parsedResp &&
        parsedResp.success &&
        parsedResp.data &&
        parsedResp.data.models && [parsedResp.data.models.length]
      ) {
        dispatch(receiveModels(parsedResp.data.models));
        return parsedResp.data.models[0];
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error while importing model.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error retrieving model.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };
