import { IOutputTypeInputFieldListValue } from "../../utilities/types/OutputTypeInputFieldListValue";


export const ACTIONS_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUE = {
    INVALIDATE: 'INVALIDATE_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUES',
    RECEIVE: 'RECEIVE_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUES',
    DELETE: 'DELETE_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUE',
    UPDATE: 'UPDATE_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUES',
}

interface ActionOutputTypeInputFieldListValuesInvalidate {
    type: typeof ACTIONS_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUE.INVALIDATE,
    byId: { [key: string]: IOutputTypeInputFieldListValue },
}

interface ActionOutputTypeInputFieldListValuesReceive {
    type: typeof ACTIONS_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUE.RECEIVE
    byId: { [key: string]: IOutputTypeInputFieldListValue },
}

export interface OutputTypeInputFieldListValueState {
    byId: { [key: string]: IOutputTypeInputFieldListValue },
}

export enum OutputTypeInputFieldListValueSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesOutputTypeInputFieldListValue = ActionOutputTypeInputFieldListValuesReceive | ActionOutputTypeInputFieldListValuesInvalidate;