import { ICause } from "../../utilities/types/Cause";


export const ACTIONS_CAUSE = {
    INVALIDATE: 'INVALIDATE_DECISIONS',
    RECEIVE: 'RECEIVE_DECISIONS',
    DELETE: 'DELETE_DECISION',
    UPDATE: 'UPDATE_DECISIONS',
}

interface ActionCausesInvalidate {
    type: typeof ACTIONS_CAUSE.INVALIDATE,
    byId: { [key: string]: ICause },
}

interface ActionCausesReceive {
    type: typeof ACTIONS_CAUSE.RECEIVE
    byId: { [key: string]: ICause },
}

export interface CauseState {
    byId: { [key: string]: ICause },
}

export enum CauseSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesCause = ActionCausesReceive | ActionCausesInvalidate;