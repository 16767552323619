import React from "react";
import { TextField, Grid, Divider, Button } from "@mui/material";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import { InjectedFormikProps, withFormik } from "formik";
import * as Yup from "yup";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../../redux";
import {
  IOutputTypeInputField,
  OutputTypeInputFieldTypeEnum,
} from "../../../utilities/types/OutputTypeInputField";
import { fetchCreateOutputTypeInputField } from "../../../redux/outputTypeInputField/actions";

interface FormValues {
  outputTypeId: string;
  label: string;
  description: string;
  defaultValue: number;
  defaultOutputTypeInputFieldListValueId: string;
  minValue: number;
  maxValue: number;
  tableName: string;
  orderNumber: number;
  type: OutputTypeInputFieldTypeEnum;
}

interface FormProps {
  outputTypeId: string;
  onCompleteCallback(outputTypeInputField?: IOutputTypeInputField): void;
  onCancelCallback(): void;
  dispatch: ThunkDispatch<RootState, IOutputTypeInputField, AnyAction>;
}

const InnerForm: React.FC<InjectedFormikProps<FormProps, FormValues>> = (props) => {
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={props.handleChange}
            {...getFormikFieldProps(props, "label", "Label")}
            fullWidth
            variant="standard"
            margin="normal"
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            onChange={props.handleChange}
            multiline
            rows={3}
            variant="standard"
            fullWidth
            margin="normal"
            {...getFormikFieldProps(props, "description", "Description")}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider light={true} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "right" }}>
          <Button disabled={props.isSubmitting} variant="text"  onClick={props.onCancelCallback}>
            Close
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button type="submit" disabled={props.isSubmitting} variant="outlined" color="primary">
            Create
          </Button>
          <LoaderAbsoluteCentred loading={props.isSubmitting} />
        </Grid>
      </Grid>
    </form>
  );
};

const FormOutputTypeInputFieldCreate = withFormik<FormProps, FormValues>({
  mapPropsToValues: (props) => ({
    defaultValue: 0,
    outputTypeId: props.outputTypeId,
    label: "",
    description: "",
    defaultOutputTypeInputFieldListValueId: "",
    minValue: 0,
    maxValue: 0,
    tableName: "",
    orderNumber: 0,
    type: OutputTypeInputFieldTypeEnum.Number,
  }),
  validationSchema: Yup.object().shape({
    label: Yup.string()
      .label("Label")
      .min(3, "Please input 3 characters or more")
      .max(50, "Please input 50 characters or less")
      .required("Please provide a label"),
    description: Yup.string().label("Description").max(500, "Please input 500 characters or less"),
    defaultValue: Yup.number().label("Default Value").min(0, "Zero or more.").max(999999999, "Less than 999999999"),
    minValue: Yup.number().label("Min Value").min(0, "Zero or more.").max(999999999, "Less than 999999999"),
    maxValue: Yup.number().label("Max Value").min(0, "Zero or more.").max(999999999, "Less than 999999999"),
    orderNumber: Yup.number()
      .label("Max Value")
      .min(0, "Zero or more.")
      .required("Please provide an order number")
      .max(999, "Less than 999"),
  }),
  handleSubmit: async (values, { setSubmitting, props }) => {
    const { onCompleteCallback, dispatch, outputTypeId } = props;

    // Map dispatch via props
    var createdOutputTypeInputField = await dispatch(
      fetchCreateOutputTypeInputField({
        ...values,
        outputTypeId,
      })
    );

    setSubmitting(false);

    if (createdOutputTypeInputField) onCompleteCallback(createdOutputTypeInputField);
  },
})(InnerForm);

export default FormOutputTypeInputFieldCreate;
