import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetOutputTypeInputFieldListValuesByOutputTypeInputFieldId } from "../../redux/outputTypeInputFieldListValue/selectors";
import { TOutputTypeInputFieldValue } from "../../utilities/types/OutputType";
import { IOutputTypeInputField } from "../../utilities/types/OutputTypeInputField";

interface IOutputTypeInputFieldDisplayEnumerationProps {
  onValueChangeCallback(fieldId: string, value: TOutputTypeInputFieldValue): void;
  outputTypeInputField: IOutputTypeInputField;
  disabled?: boolean;
  overriddenDefaultValue?: TOutputTypeInputFieldValue;
}

function TypeInputFieldDisplayEnumeration({
  outputTypeInputField,
  disabled,
  onValueChangeCallback,
  overriddenDefaultValue,
}: IOutputTypeInputFieldDisplayEnumerationProps) {
  const enumerationFieldValues = useSelector((store: RootState) =>
    selectorGetOutputTypeInputFieldListValuesByOutputTypeInputFieldId(
      store,
      outputTypeInputField.outputTypeInputFieldId
    )
  );
  const labelRef: any = useRef();
  // const labelWidth = labelRef.current !== undefined ? (labelRef.current as any).clientWidth : 0;
  const [error, setError] = useState("");
  const [currentVal, setCurrentVal] = useState(outputTypeInputField.defaultValue);

  function changeValue(e: any) {
    if (isNaN(e.target.value)) {
      setError("A valid number is required.");
      return;
    }

    var index = Number(e.target.value);

    setError("");
    setCurrentVal(index);

    // Invoke callback
    onValueChangeCallback(outputTypeInputField.outputTypeInputFieldId, {
      value: enumerationFieldValues[index].value,
      outputTypeInputFieldValueId: enumerationFieldValues[index].outputTypeInputFieldListValueId,
    });
  }

  // Determine which value should be populated
  useEffect(() => {
    var defaultValueId = overriddenDefaultValue
      ? overriddenDefaultValue.outputTypeInputFieldValueId
      : outputTypeInputField.defaultOutputTypeInputFieldListValueId;
    var defaultValueIndex = enumerationFieldValues.findIndex(
      (x) => x.outputTypeInputFieldListValueId === defaultValueId
    );
    setCurrentVal(defaultValueIndex);
  }, [enumerationFieldValues, outputTypeInputField, overriddenDefaultValue]);

  return (
    <FormControl fullWidth={true} variant="outlined">
      <InputLabel
        id={`lbl-field-${outputTypeInputField.outputTypeInputFieldId}`}
        ref={labelRef}
        variant="standard"
      >
        {outputTypeInputField.label}
      </InputLabel>
      <Select
        labelId="lbl-model-type"
        variant="standard"
        style={{ display: "block", overflow: "hidden" }}
        id="txt-model-type"
        value={currentVal}
        error={!enumerationFieldValues.length || error !== ""}
        {...(disabled === true ? { disabled } : null)}
        onChange={changeValue}
      >
        {enumerationFieldValues.map((enumerationFieldValue, i) => {
          return (
            <MenuItem key={enumerationFieldValue.outputTypeInputFieldListValueId} value={i}>
              {enumerationFieldValue.displayText}
            </MenuItem>
          );
        })}
      </Select>
      {error ? <FormHelperText error={error !== ""}>{error}</FormHelperText> : null}
      {!enumerationFieldValues.length ? <FormHelperText>Edit this field to add options.</FormHelperText> : null}
    </FormControl>
  );
}

export default TypeInputFieldDisplayEnumeration;
