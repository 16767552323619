import { AppThunk } from "..";
import { AddQueryStringsToUrl, CheckStatus, GetDefaultHeaders, ShowExceptionAsMessage } from "../../utilities/ApiUtils";
import { Configuration } from "../../utilities/Constants";
import { PrepareBody, ShowError } from "../../utilities/Helpers";
import { IApiResponse } from "../../utilities/types/Api";
import { IOutputJob, OutputJobStatusEnum } from "../../utilities/types/OutputJob";
import { TOutputTypeInputFieldValue } from "../../utilities/types/OutputType";
import { ACTIONS_OUTPUT_JOB, OutputJobSearchOrderTypeEnum } from "./types";

export const receiveOutputJob = (outputJobs: IOutputJob[]) => {
  var byIdObjectToDispatch: { [key: string]: IOutputJob } = {};

  for (var i = 0; i < outputJobs.length; i++) {
    byIdObjectToDispatch[outputJobs[i].outputJobId] = outputJobs[i];
  }

  return {
    type: ACTIONS_OUTPUT_JOB.RECEIVE,
    byId: byIdObjectToDispatch,
  };
};

export const requestDeleteOutputJob = (outputJob: IOutputJob) => ({
  type: ACTIONS_OUTPUT_JOB.DELETE,
  byId: { [outputJob.outputJobId]: outputJob },
});

interface IPrerequisites {
  assetJobIds?: string[];
  outputJobIds?: string[];
  solverJobIds?: string[];
}

export interface IFetchCreateOutputJobProps {
  solverJobId: string;
  outputTypeInputs: { [id: string]: IOutputTypeInput };
  inputBucket?: string;
  outputBucket?: string;
  prerequisites?: IPrerequisites;
}

export interface IOutputTypeInput {
  outputTypeId: string;
  displayName: string;
  inputFieldValueMap: { [id: string]: TOutputTypeInputFieldValue };
}

export const fetchCreateOutputJob =
  (request: IFetchCreateOutputJobProps): AppThunk<Promise<IOutputJob>> =>
    async (dispatch) => {
      var headers = await GetDefaultHeaders(true, true);

      try {
        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/outputJobs`, {
          method: "POST",
          headers: headers,
          body: PrepareBody(request),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.outputJobs) {
          dispatch(receiveOutputJob(parsedResp.data.outputJobs));
          return parsedResp.data.outputJobs;
        } else {
          if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
            ShowError("Error creating reports.");
            return null;
          }
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          ShowExceptionAsMessage(e);
          console.log("Error creating reports.", e.stack);
        } else {
          // Handle other types of exceptions or unknown errors.
          console.error("Unknown error:", e);
        }
        return;
      }
    };

export interface IFetchUpdateOutputJobProps {
  outputJobId: string;
  name: string;
  description: string;
  orderNumber: number;
}

export const fetchUpdateOutputJob =
  (props: IFetchUpdateOutputJobProps): AppThunk<Promise<IOutputJob>> =>
    async (dispatch) => {
      var headers = await GetDefaultHeaders(true, true);

      try {
        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/outputJobs`, {
          method: "PUT",
          headers: headers,
          body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.outputJobs) {
          dispatch(receiveOutputJob(parsedResp.data.outputJobs));
          return parsedResp.data.outputJobs;
        } else {
          if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
            ShowError("Error updating output job.");
            return null;
          }
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          ShowExceptionAsMessage(e);
          console.log("Error updating output job.", e.stack);
        } else {
          // Handle other types of exceptions or unknown errors.
          console.error("Unknown error:", e);
        }
        return;
      }
    };

export interface IFetchSearchOutputJobProps {
  pageNumber: number;
  pageSize: number;
  outputJobId?: string;
  solverJobId?: string;
  outputTypeId?: string;
  text?: string;
  status?: OutputJobStatusEnum;
  createdBy?: string;
  orderType?: OutputJobSearchOrderTypeEnum;
}

export const fetchSearchOutputJob =
  (searchParams: IFetchSearchOutputJobProps): AppThunk<Promise<IOutputJob[]>> =>
    async (dispatch) => {
      var headers = await GetDefaultHeaders(true, false, true);

      try {
        var apiResponse = await fetch(
          AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/outputJobs`, searchParams),
          {
            method: "GET",
            headers: headers,
          }
        );

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.outputJobs) {
          dispatch(receiveOutputJob(parsedResp.data.outputJobs));
          return parsedResp.data.outputJobs;
        } else {
          if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
            ShowError("Error searching reports.");
            return [];
          }
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          ShowExceptionAsMessage(e);
          console.log("Error searching reports.", e.stack);
        } else {
          // Handle other types of exceptions or unknown errors.
          console.error("Unknown error:", e);
        }
        return [];
      }
    };

export interface IFetchDeleteOutputJobProps {
  outputJobId: string;
}

export const fetchDeleteOutputJob =
  (props: IFetchDeleteOutputJobProps): AppThunk<Promise<IOutputJob>> =>
    async (dispatch) => {
      var headers = await GetDefaultHeaders(true, true);

      try {
        var apiResponse = await fetch(
          AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/outputJobs`, props),
          {
            method: "DELETE",
            headers: headers,
          }
        );

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (
          parsedResp &&
          parsedResp.success &&
          parsedResp.data &&
          parsedResp.data.outputJobs &&
          parsedResp.data.outputJobs.length
        ) {
          dispatch(requestDeleteOutputJob(parsedResp.data.outputJobs[0]));
          return parsedResp.data.outputJobs[0];
        } else {
          if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
            ShowError("Error deleting output job.");
            return null;
          }
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          ShowExceptionAsMessage(e);
          console.log("Error deleting output job.", e.stack);
        } else {
          // Handle other types of exceptions or unknown errors.
          console.error("Unknown error:", e);
        }
        return;
      }
    };

export interface ICancelOutputJobProps {
  outputJobId: string;
}

export const cancelOutputJob =
  (request: ICancelOutputJobProps): AppThunk<Promise<any>> =>
    async () => {
      var headers = await GetDefaultHeaders(true, true);

      try {
        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/outputJobs/cancel`, {
          method: "POST",
          headers: headers,
          body: PrepareBody(request),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success) {
          return parsedResp.data;
        } else {
          if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
            ShowError("Error cancelling reports.");
            return null;
          }
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          ShowExceptionAsMessage(e);
          console.log("Error cancelling reports.", e.stack);
        } else {
          // Handle other types of exceptions or unknown errors.
          console.error("Unknown error:", e);
        }
        return;
      }
    };
