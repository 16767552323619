import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IFetchSearchOutputTypeInputFieldsProps, fetchSearchOutputTypeInputFields } from "../../redux/outputTypeInputField/actions";
import { IOutputTypeInputField } from "../../utilities/types/OutputTypeInputField";


interface IUseFetchOutputTypeInputFieldsPageHookProps extends IFetchSearchOutputTypeInputFieldsProps {
    minPageNumberToFetch: number;
}


export const useFetchOutputTypeInputFieldsPageHook = ({ pageNumber, minPageNumberToFetch, pageSize, outputTypeId }: IUseFetchOutputTypeInputFieldsPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<IOutputTypeInputField[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<IOutputTypeInputField[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve models
                var outputTypeInputFields = await dispatch(fetchSearchOutputTypeInputFields({
                    pageSize,
                    pageNumber,
                    outputTypeId,
                })) as unknown as IOutputTypeInputField[];

                if (outputTypeInputFields && outputTypeInputFields.length) {
                    setMorePages(outputTypeInputFields.length >= pageSize)
                    setLastResultSet(outputTypeInputFields);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, outputTypeId]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.outputTypeInputFieldId === x.outputTypeInputFieldId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}