import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import { IOutputType } from "../../../utilities/types/OutputType";
import MapIcon from "@mui/icons-material/MapRounded";
import FormOutputTypeCreate from "../forms/FormOutputTypeCreate";

export interface IModalOutputTypeCreateProps {
  open: boolean;
  onCancelCallback(): void;
  onCompleteCallback(outputType?: IOutputType): void;
}

function ModalOutputTypeCreate({
  onCancelCallback,
  open,
  onCompleteCallback,
}: IModalOutputTypeCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Create a new  output type"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<MapIcon />}
    >
      <DialogContent>
        <FormOutputTypeCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalOutputTypeCreate;
