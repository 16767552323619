import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import RouteUsers from "./RouteUsers";
import LayoutDefault from "../components/layout/LayoutDefault";
import RouteDashboard from "./RouteDashboard";
import RouteModels from "./RouteModels";
import RouteJobs from "./RouteJobs";
import RouteAdmin from "./RouteAdmin";
import PageNoPermissions from "../components/generic/pages/PageNoPermissions";
import { useDispatch } from "react-redux";
import { fetchUserDetailsByIdIfNeeded } from "../redux/userDetail/actions";
import { fetchSearchUserPermissions } from "../redux/userPermission/actions";
import LoaderAbsoluteCentred from "../components/generic/loaders/LoaderAbsoluteCentred";

import usePageTracking from "../components/generic/utils/ReactRouterAnalytics";
import { fetchEntityPermissionsByUserIdIfNeeded } from "../redux/entityPermission/actions";
import { useUserId } from "../utilities/ApiUtils";
import RouteSolvers from "./RouteSolvers";
import PageHome from "../components/home/pages/Home";
import RouteHome from "./RouteHome";
import RouteOutputs from "./RouteSummaryOutputs";
import RouteWorkflow from "./RouteWorkflow";
import ModalInsufficientUserPermissions from "../components/userPermissions/modals/ModalInsufficientUserPermissions";
import RouteAssetJobs from "./RouteAssetJobs";
import RouteOutputJob from "./RouteOutputJob";

const RouteManagerCustom = () => {
  const userId = useUserId();
  const [fetchingUser, setFetchingUser] = useState(false);
  const [fetchingUserPermissions, setFetchingUserPermissions] = useState(false);
  const dispatch = useDispatch();

  // Enable google analytics
  usePageTracking();

  // Preload the current user's details, permissions, etc.
  useEffect(() => {
    setFetchingUser(true);

    (async () => {
      if (userId) {
        setFetchingUserPermissions(true);

        await Promise.all([
          dispatch(fetchUserDetailsByIdIfNeeded(userId)),
          dispatch(fetchEntityPermissionsByUserIdIfNeeded({ userDetailId: userId })),
          dispatch(fetchSearchUserPermissions({ userDetailId: userId })),
        ]);

        setFetchingUserPermissions(false);
      }

      setFetchingUser(false);
    })();
  }, [userId, dispatch]);

  return (
    <>
      <Switch>
        <Route exact={true} path="/login" component={PageNoPermissions} />
        <Route path={"/home"}>
          <RouteHome />
        </Route>
        <Route path={"/admin"}>
          <RouteAdmin />
        </Route>
        <Route path={"/solvers"}>
          <RouteSolvers />
        </Route>
        <Route path={"/users"}>
          <RouteUsers />
        </Route>
        <Route path={"/search"}>
          <RouteModels />
        </Route>
        <Route path={"/outputs"}>
          <RouteOutputs />
        </Route>
        <Route path={"/solver-job"}>
          <RouteJobs />
        </Route>
        <Route path={"/asset-job"}>
          <RouteAssetJobs />
        </Route>
        <Route path={"/output-job/:outputJobId"}>
          <RouteOutputJob />
        </Route>
        <Route path={"/dashboard"}>
          <RouteDashboard />
        </Route>
        <Route path={"/Workflow"}>
          <RouteWorkflow />
        </Route>
        <Route
          render={(props) => (
            <LayoutDefault routeProps={props}>
              <PageHome {...props} />
            </LayoutDefault>
          )}
        />
      </Switch>
      <Switch>
        <PageNoPermissions />
      </Switch>
      <LoaderAbsoluteCentred loading={fetchingUser || fetchingUserPermissions} />
      <ModalInsufficientUserPermissions />
    </>
  );
};

export default RouteManagerCustom;
