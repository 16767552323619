export interface IOutputType {
    outputTypeId: string,
    name: string,
    description: string,
    inputBucket: string,
    triggerBucket: string,
    outputBucket: string,
    status: OutputTypeStatus,
    mainImageId: string,
    mainImage: string,
    created: Date,
    updated: Date,
    createdBy: string,
    updatedBy: string,
}

export enum OutputTypeSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export enum OutputTypeStatus {
    AdminOnly = 10,
    Preview = 20,
    Enabled = 30
}

export type TOutputTypeInputFieldValue = {
    value?: number,
    valueString?: string,
    valueDateTime?: Date,
    outputTypeInputFieldValueId?: string,
}
